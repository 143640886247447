import React from "react";
import Layout from "../../common/Layout";
import SEO from "../../common/SEO";
import FooterOne from "../../common/footer/FooterOne";

const AszfIndex = ({mobile = false}) => {
    if(mobile) {
        return <div className={"aszf-container"}>
            <div>
                <p className="normal0" style={{marginBottom: "36pt", textAlign: "center"}}>
                <span style={{fontSize: 28, color: "#212529",}}>
                          Általános Szerződési Feltételek
                        </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid"}}></p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                <span style={{fontFamily: '"Helvetica Neue"'}}>
                  I. Bevezető ÁSZF Célja
                </span>
                    <span style={{width: "340.38pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_gjdgxs" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                2
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_gjdgxs" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              II. Szolgáltató adatai
            </span>
                    <span style={{width: "358.67pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_30j0zll" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                2
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_30j0zll" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              III. A Szolgáltatás
            </span>
                    <span style={{width: "372.65pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_1fob9te" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                2
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_1fob9te" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              IV. Felek közötti szerződéses jogviszony létrejövetele
            </span>
                    <span style={{width: "172.44pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_3znysh7" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                4
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_3znysh7" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              V. Fizetési feltételek, bankkártya regisztráció
            </span>
                    <span style={{width: "220.68pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_2et92p0" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                6
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_2et92p0" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              VI. Számlázás és fizetési késedelem és pénzvisszafizetés
            </span>
                    <span style={{width: "156.54pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_tyjcwt" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                7
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_tyjcwt" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              VII. A Szolgáltatás szezonális igénybevétele, szolgáltatás
              szüneteltetése, megtagadása
            </span>
                    <span style={{width: "36pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_3dy6vkm" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                7
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_3dy6vkm" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              VIII. Felelősségvállalás
            </span>
                    <span style={{width: "341.94pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_1t3h5sf" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                8
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_1t3h5sf" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              IX. A szerződés megszüntetése/Felmondási jog
            </span>
                    <span style={{width: "202.36pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_4d34og8" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                10
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_4d34og8" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              X. Titoktartás
            </span>
                    <span style={{width: "390.14pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_2s8eyo1" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                11
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_2s8eyo1" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              XI. Szavatosság/Jótállás/Hibás teljesítés
            </span>
                    <span style={{width: "242.78pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_3rdcrjn" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                12
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_3rdcrjn" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              XII. Szerzői jogok
            </span>
                    <span style={{width: "364.1pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_26in1rg" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                13
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_26in1rg" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              XIII. Jogi nyilatkozat
            </span>
                    <span style={{width: "347.77pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_lnxbz9" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                14
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_lnxbz9" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              XIV. Vis Maior
            </span>
                    <span style={{width: "378.86pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_35nkun2" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                15
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_35nkun2" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              XV. Adatvédelem
            </span>
                    <span style={{width: "366.04pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_1ksv4uv" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                15
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_1ksv4uv" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              XVI. Panaszügyintézés és jogérvényesítés
            </span>
                    <span style={{width: "231.53pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_44sinio" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                15
              </span>
                    </a>
                </p>
                <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                    <a href="#_44sinio" style={{textDecoration: "none"}}/>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              XVII. Egyéb rendelkezések
            </span>
                    <span style={{width: "312.81pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                    <a href="#_2jxsxqh" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                15
              </span>
                    </a>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid"}}>
                    <br style={{pageBreakBefore: "always", clear: "both"}}/>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_gjdgxs"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                I. Bevezető ÁSZF Célja
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. Jelen Általános Szerződési Feltételek (továbbiakban: ÁSZF)
              célja, hogy összefoglalja a Happy Gastro Kft. (Továbbiakban:
              Szolgáltató) által a{" "}
            </span>
                    <a href="http://www.happygastro.hu" style={{textDecoration: "none"}}>
                        <u>
                <span
                    style={{
                        fontFamily: "Avenir",
                        color: "#212529",
                        backgroundColor: "#f7f8fc",
                    }}
                >
                  www.happygastro.hu
                </span>
                        </u>
                    </a>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              {" "}
                        domain név alatt üzemeltetett weboldalon (továbbiakban: weboldal)
              keresztül elérhető szolgáltatást megrendelő személy (a
              továbbiakban: Felhasználó) között létrejövő szerződés tartalmát,
              az igénybevétel feltételeit, a felek jogait és kötelezettségeit,
              továbbá a vonatkozó jogszabályok rendelkezéseit.{" "}
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Felhasználó a szolgáltatás megrendelése előtt köteles
              megismerni a jelen ÁSZF rendelkezéseit. A szolgáltatás
              megrendelésével, használatával a Felhasználó elfogadja a jelen
              ÁSZF rendelkezéseit.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              3. A mindenkor aktuális ÁSZF folyamatosan elérhető a{" "}
            </span>
                    <a href="http://www.happygastro.hu/aszf" style={{textDecoration: "none"}}>
                        <u>
                <span
                    style={{
                        fontFamily: "Avenir",
                        color: "#212529",
                        backgroundColor: "#f7f8fc",
                    }}
                >
                  www.happygastro.hu/aszf
                </span>
                        </u>
                    </a>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              {" "}
                        weboldal helyen.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. Jelen ÁSZF tárgyi hatálya kiterjed a Happy Gastro Kft. És
              weboldalán elérhető ill. hozzáférhető szolgáltatások
              (továbbiakban: szolgáltatás) igénybevételére, valamint azzal
              összefüggő lényeges feltételekre. Személyi hatálya kiterjed
              valamennyi a weboldalt látogató és a vállalkozó által nyújtott
              bármely szolgáltatást igénybe vevő Felhasználóra, vállalkozóra.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              5. A Szolgáltatás használatának megkezdésével a Felhasználó és a
              Szolgáltató között online szerződés jön létre, a jelen
              Szabályzatban foglalt feltételekkel. Szolgáltató fenntartja a
              jogot, hogy a Happy Solutions weboldal / mobil alkalmazás /software
              bármely tartalmi elemét bármikor előzetes figyelmeztetés nélkül
              módosítsa vagy megszüntesse, azok megjelenését, tartalmát,
              működését megváltoztassa vagy hozzáférhetőségét a jelen ÁSZF-ben
              foglaltak betartásával megszüntesse
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_30j0zll"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                II. Szolgáltató adatai
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "2pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Név:
            </span>
                    <span style={{
                        width: "12.67pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{
                        width: "36pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{
                        width: "36pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Happy Gastro Kft
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "2pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Székhely:
            </span>
                    <span style={{
                        width: "24.68pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{
                        width: "36pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1034 Budapest, Bécsi út 89-91. fsz.1.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "2pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Cégjegyzékszám:
            </span>
                    <span style={{
                        width: "23.37pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              01-10-041037
            </span>
                </p>
                <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Adószám:
            </span>
                    <span style={{
                        width: "23.34pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{
                        width: "36pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              28735636-1-41
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Telefonszám:
            </span>
                    <span style={{
                        width: "6.7pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{
                        width: "36pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              +36-1-300-91-05
            </span>
                    <br/>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Weblap:{" "}
            </span>
                    <span style={{
                        width: "28.35pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{
                        width: "36pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              www.happygastro.hu
            </span>
                    <br/>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              E-mail:{" "}
            </span>
                    <span style={{
                        width: "33.01pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{
                        width: "36pt",
                        fontFamily: "Avenir",
                        backgroundColor: "#f7f8fc",
                        display: "inline-block",
                    }}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              info@happygastro.hu
            </span>
                    <br/>
                    <span style={{fontFamily: "Avenir", color: "#212529"}}>
              &nbsp;
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_1fob9te"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                III. A Szolgáltatás
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              1
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Az “Happy Gastro” egy éttermi rendszer (a továbbiakban:
              Szolgáltatás), mely vendéglátó egységek működését segíti:
              rendelések kezelésével mind a la carte, kioszk, elviteles
              formában, házhozszállításos rendelések kezelése futárokkal és
              külső integrációkkal, jelentések elkészítésével stb. A
              Szolgáltatáshoz tartozik a weboldal, az ehhez kötődő
              háttérszerverek és minden olyan program, szoftver vagy hardver,
              ami a weboldal háttérszervereihez csatlakozik, adatot kér le és a
              Szolgáltató tulajdonát képezi, beleértve az egyéb csatornákat
              (digitális, offline), ami a Szolgáltatás működését segíti,
              támogatja vagy függ tőle.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató három fő csomagot kínál a Felhasználók részére. A
              „Mini csomag” azoknak a vendéglátó helyeknek praktikus, akik a
              kisebb méret és használat miatt nincs szüksége több funkciókra. Az
              „Alap csomag” a közepes és nagy vendéglátó egységeknek hasznos,
              akiknek fő profilja a beltéri asztalos kiszolgálás és/vagy a
              kiszállítás. A „Prémium csomag” a nagyobb funkcionalitást igénylő
              vendéglátó egységeknek hasznos, ami tartalmilag az Alap csomag
              kiegészítve további kiegészítő csomagokkal. A Felhasználó a három
              fő csomag egyikére tud előfizetni. A Felhasználó amennyiben egyik
              csomag sem felel meg a vállalkozásának úgy egyénre szabott
              csomagot is össze tud saját maga számára állítani a honlapon.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató lehetőséget biztosít ún. kiegészítő csomagok
              igénybevételére. Az aktuálisan elérhető kiegészítő csomagokról és
              azok díjáról a Felhasználó a Weboldalon tud tájékozódni.
              Kiegészítő csomag kizárólag valamelyik fő csomagra („Mini csomag”,
              „Alap csomag”, „Prémium csomag” vagy “Egyéni csomag”) történő
              előfizetés mellett igényelhető. A szolgáltatásokon túl Szolgáltató
              biztosít Hardware eszközöket és kiegészítő
              termékeket/szolgáltatásokat is melyeknek ára és pontos termék
              leírása a honlapon megtalálható.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              4
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A szolgáltatások részleteiről és díjáról a Felhasználó a
              Weboldalon tájékozódhat. A megjelenített árak forintban értendők
              és az Általános Forgalmi Adó (ÁFA) összegét nem tartalmazzák,
              eszközönként értendők, havi díjak és előre fizetendők. Kivéve
              Hardware és Hardware support mely alkalmi díjas és nem havi
              díjazású.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              5
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató fenntartja magának a jogot a Szolgáltatás
              funkcióinak és különféle szolgáltatásainak korlátozására,
              változtatására, levételére és hozzáadására. A Felhasználó
              kifejezetten elfogadja, hogy nem minden esetben ugyanazokat a
              funkciókat és szolgáltatásokat láthatja és használhatja, mint más
              Felhasználók. A Felhasználó ezzel szemben semmilyen jogcímen nem
              támaszthat követelést vagy igényt a Szolgáltatóval szemben.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              6
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató a Felhasználó részére kizárólag hozzáférést
              biztosít a Rendszerhez, annak tulajdonát nem ruházza át, az a
              Szolgáltató tulajdonában marad.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              7. A Felhasználó jogosult alkalmazottat is kijelölni a
              Szolgáltatás használatára. Ezt meghaladóan a Felhasználó a
              Szolgáltatást csak maga használhatja, harmadik személynek ahhoz
              hozzáférést nem biztosíthat. Felhasználó a használati jogát nem
              ruházhatja át harmadik személynek sem ingyenesen, sem
              visszterhesen.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              8. A Weboldalon történő regisztrációval a Felhasználó elismeri,
              hogy a Szolgáltatást gazdasági tevékenységének körében és nem
              fogyasztóként használja, és a saját vagy az általa képviselt
              gazdálkodó szervezet nevében regisztrál.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              9. A Szolgáltató a Felhasználó csatlakozási szándékát jogosult
              indokolás nélkül elutasítani.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              10. Amennyiben a weboldalon hiba vagy hiányosság lép fel a
              termékeknél/szolgáltatásoknál vagy az áraknál, fenntartjuk a jogot
              a korrekcióra. Ilyen esetben a hiba felismerése illetve módosítása
              után azonnal tájékoztatjuk a vevőt az új adatokról. Felhasználó
              ezt követően még egyszer megerősítheti a rendelést vagy elállhat a
              szerződéstől.{" "}
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_3znysh7"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                IV. Felek közötti szerződéses jogviszony ix
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "1pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. Érdeklődés/Regisztráció
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A Felhasználó regisztráció után az “ajánlat kérése” gombra
              kattintva kifejezheti érdeklődését a Szolgáltatással kapcsolatban
              annak érdekében, hogy vele a Szolgáltató a szerződés megkötése
              érdekében felvegye a kapcsolatot. A jelentkezés során a
              Felhasználónak meg kell adnia a cég vagy egyéni vállalkozás és az
              étterem nevét, adószámát, valamint kapcsolattartói adatokat (név,
              telefonszám, e-mail cím), továbbá, hogy kiszállítós (és/vagy)
              helyben értékesítő éttermet üzemeltet és várhatóan mennyi eszközre
              lesz szüksége, megvásárolni kívánt szolgáltatást és azok
              darabszámát (ahol lehet, beállítja). Továbbá el kell fogadja az
              ÁSZF-t.
            </span>
                </p>
                <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Demo
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A regisztrációval a Felhasználó 3 napos demó hozzáférést kap a
              kiválasztott fő csomagra emailen. Ezen időszak alatt a Felhasználó
              feltöltheti étterme adatait vagy külön díj ellenében megbízhatja a
              Szolgáltatót ezzel a feladattal. A demó időszak alatt a
              Szolgáltatás bármikor díjmentesen lemondható. A demó időszak
              letelte után a Felhasználó részére kiterhelésre kerül a havidíj és
              megkezdődik a szerződéses jogviszony. A feltöltött adatokat az
              éles rendszer átveszi, nem szükséges azt még egyszer feltölteni.
            </span>
                </p>
                <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Szerződéskötés
            </span>
                </p>
                <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              A Felhasználó ajánlat kérésére egy automatikusan generált
              visszaigazoló email érkezik. Felhasználó tudomásul veszi, hogy
              ezen automatikus visszaigazolással szerződés nem keletkezett.
              Szerződés akkor jön létre, amikor emailen érkező ajánlatot
              (melyben a megrendelés részletei) Felhasználó a megrendelésével
              visszaigazolja. Innentől fizetési kötelezettsége keletkezik.
            </span>
                </p>
                <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              Szolgáltató kizárja a visszaigazolási felelősséget, ha a
              visszaigazolás azért nem érkezik meg időben, mert Felhasználó
              rossz e-mail címet adott meg a regisztrációja során, vagy a
              fiókjához tartozó tárhely telítettsége miatt nem tud üzenetet
              fogadni. Megrendelő, Felhasználó a megrendelési folyamat lezárása
              előtt minden esetben vissza tud lépni az előző fázisba és a bevitt
              adatait javítani tudja.
            </span>
                </p>
                <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              A Felhasználó megadott email címére majd a következő generált
              email már az ajánlattétel - írásban véglegesíti a Felhasználóval a
              megrendelés tartalmát, mely elfogadására oly módon van lehetőség,
              hogy a megjelölt link a honlapra vezeti, ahol befejezheti
              Felhasználó a vásárlást. Felhasználó a megrendeléssel tudomásul
              veszi, hogy fizetési kötelezettsége keletkezik. Miután megrendelte
              és a honlapon kifizette előre a kívánt szolgáltatást,{" "}
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              ezt követően e-mailben megkapja a Szolgáltatás igénybevételéhez a
              felhasználónevet és a jelszót. A Felhasználó csak abban az esetben
              tudja használni a Szolgáltatást, ha elfogadja az Általános
              Szerződési Feltételeket és megadja bankkártya adatait a havidíj
              fizetéséhez, továbbá legalább 1 havi díjat kifizetett az online
              felületen bankkártyával.
            </span>
                </p>
                <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3.
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              {" "}
                        Adatfeltöltés
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A szerződéskötést követő hét napon belül a Szolgáltató feltölti az
              étterem adatait, amennyiben külön díj ellenébe megrendelte és a
              vendéglátó egység nem maga tölti fel.{" "}
            </span>
                </p>
                <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. Szállítási feltételek - Hardware eszközök vétele
            </span>
                </p>
                <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Hardware eszközök rendelésekor a fizetendő végösszeget
              kiegyenlítése után, Szolgáltató beszerzi és feladja az eszközt
              csomag formájában amennyiben nem tudja a helyszínre szállítani. A
              csomag tartalmazza az eszközt/eszközöket, a számlát és a jótállási
              jegyet. A felhasználó köteles a csomagot kézbesítéskor a futár
              előtt megvizsgálni és termékeken csomagolás észlelt esetleges
              sérülés esetén köteles jegyzőkönyv felvételét kérni, sérülés
              esetén a csomagot nem köteles átvenni. Utólagos jegyzőkönyv
              nélküli reklamációt Szolgáltató nem fogad el!{" "}
            </span>
                </p>
                <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              5. Technikai feltételek, hibakezelés
            </span>
                </p>
                <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A technikai feltételeket honlapunkon közöljük, mely tartalmazza a
              Happy Gastro software helyes működéséhez a Hardware igényt.{" "}
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              Szolgáltató a megrendelőnek a szolgáltatás gyorsasága érdekében
              felajánlhatja az adatok archiválását, amit ha nem fogad el a
              Felhasználó, akkor számolnia kell vele, hogy a rendszer lassabb
              lehet.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "16pt"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              Ügyfélszolgálatunk munkanapokon 10-18h fogadja a hívást a{" "}
            </span>
                    <strong>
              <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
                06-1-300-91-05
              </span>
                    </strong>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              -ös telefonszámon, vagy ha Felhasználó
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              admin felületen kitölt egy hibajegyet az ügyfélszolgálati űrlapon
              vagy emailt küld a
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                    <strong>
              <span style={{fontFamily: "Avenir"}}>
                support@happygastro.hu
              </span>
                    </strong>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              címre, akkor vállaljuk, hogy egy munkanapon belül válaszol
              Szolgáltató.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "16pt"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              6. A szolgáltatási díjak
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "16pt"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              A szolgáltatások mindenkori díjszabása a weboldalon
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;{" "}
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              található. A díjakat a Felhasználó kizárólag a Szolgáltató által
              biztosított fizetési módokat használva egyenlítheti ki. A
              Szolgáltató jogosult a Szolgáltatási Díjakat
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              minden előzetes értesítés nélkül is egyoldalúan módosítani.{" "}
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "16pt"}}>
                <span style={{fontFamily: '"Helvetica Neue"'}}>
              A Felhasználó jogosult a jövőre nézve, a még nem esedékes, és meg
              nem fizetett szolgáltatások tekintetében jelen szerződés
              felmondására.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "16pt"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              A Szolgáltató főszabályként elektronikus számlát bocsát ki a
              befizetésekről.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "16pt"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              Ha külső tényező miatt a szerződés teljesítése lehetetlenné válik,
              és Felhasználó már kifizette a díjat
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              a szerződés megszűnik.
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              A teljesítés lehetetlenné válásáról tudomást szerző fél késedelem
              nélkül köteles erről a másik felet értesíteni. Az értesítés
              elmulasztásából eredő kárt a mulasztó fél köteles megtéríteni.
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              Ha a teljesítés lehetetlenné válásáért egyik fél sem felelős, a
              szerződés megszűnésének időpontját megelőzően nyújtott
              szolgáltatás pénzbeli ellenértékét meg kell téríteni. Ha a már
              teljesített pénzbeli szolgáltatásnak megfelelő ellenszolgáltatást
              a másik fél nem teljesítette, a pénzbeli szolgáltatás visszajár.
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_2et92p0"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                V. Fizetési feltételek, bankkártya regisztráció
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              1
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltatás igénybevételéhez a Felhasználó köteles a
              bankkártya adatokat megadni. Amennyiben nem áll rendelkezésre
              bankkártya adat, a Felhasználó a Szolgáltatás használatára nem
              jogosult. A Felhasználó több bankkártyát is tud regisztrálni,
              ebben az esetben ki kell jelölnie az elsődleges bankkártyát.
              Elsődleges kártyát nem lehet törölni és legalább egy bankkártyának
              mindig regisztrálva kell lennie.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2. A bankkártya regisztrálásakor a Szolgáltató 100,-Ft-ot zárol a
              bankkártyán. A zárolás a regisztráció befejezésével automatikusan
              feloldásra kerül.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Felhasználó a Szolgáltatás díját mindig előre fizeti,
              használatára akkor jogosult, ha a havidíjat megfizette. A
              Szolgáltató dönthet úgy, hogy nem vonja le, vagy nem számlázza ki
              a havidíjat, ilyenkor a Felhasználó a Szolgáltató által megszabott
              ideig ingyenesen használhatja a Szolgáltatást. Szolgáltató dönthet
              úgy is, hogy egy vagy több Felhasználó részére ideiglenesen
              ingyenesen biztosít egy csomagot. Ezt a döntését előzetes
              tájékoztatás nélkül bármikor visszavonhatja. A felhasználó akár
              több hónapot is előfizethet, de legalább 1 havi díjat szükséges
              előfizetni a szolgáltatás megkezdéséhez.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              4
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A havidíjat a Felhasználó által a Stripe oldalán megadott
              bankkártyáról automatikusan levonja a rendszer. A havidíj
              kiterhelésével a Felhasználó 1 tárgyhónap használati időre
              jogosult a Szolgáltatás használatára. A következő időszakra akkor
              történik meg az automatikus díjlevonás, amikor a használati idő 10
              napra csökken.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              5
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Amennyiben a rendszer nem tudja levonni az adott napon a
              Felhasználó által megadott bankkártyáról az összeget, akkor a
              rendszer automatikusan minden nap megpróbálja levonni az összeget,
              annak sikerességéig. Sikeresség esetén 30 nap használati idő
              jóváírásra kerül.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              6. A Felhasználónak lehetősége van előre fizetésre is. A
              Felhasználó kiválaszthatja, hogy hány hónapra szeretné előre
              kifizetni a havidíjat. Ebben az esetben a bankkártyáról történő
              levonást követően annyiszor 1 hónap használati időt ír jóvá a
              rendszer, ahány hónap kifizetésre került.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              7
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Hosszabb előfizetési idő esetén a rendszer visszaáll az eredeti
              díjlevonásra, azaz a következő időszakra akkor történik meg a
              kiterhelés, amikor a használati idő 10 napra csökken.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              8
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Ha az előfizetési idő lejárta után vagy alatt a Felhasználó
              lemondja a Szolgáltatást és a kifizetett használati idő is letelt,
              a Felhasználó Szolgáltatáshoz való hozzáférése megszűnik.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              9. Amennyiben a Felhasználó a már meglévő csomagja mellé új
              csomagra szeretne előfizetni, a rendszer arányosan csak akkora
              összeget von le a Felhasználótól az új csomag vonatkozásában,
              amennyi használati idő a már meglévő csomagban hátra van, annak
              érdekében, hogy a következő havi levonásnál minden előfizetett
              csomag esetén az összegek kiterhelése egyszerre történhessen.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              1
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              0. Az online bankkártyás fizetések a Stipe.com rendszerén
              keresztül valósulnak meg. A bankkártya-adatok a kereskedőhöz nem
              jutnak el. A szolgáltatást nyújtó Stripe Payments Europe Limited.
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_tyjcwt"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                VI. Számlázás és fizetési késedelem és pénzvisszafizetés
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. A Szolgáltató a számlát a megadott e-mail címre elektronikus
              úton küldi el.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2. Amennyiben a Felhasználó a Szolgáltató más szolgáltatásait is
              használja, úgy a felek az ottani díjakkal együtt számolnak el. A
              Felhasználó kifejezetten elfogadja, hogy ha használja a
              Szolgáltató által készített felhasználói honlap kártyás fizetési
              lehetőségét vagy egyéb szolgáltatásait, akkor a Szolgáltató az
              ottani beérkezett összegekből levonhatja a Szolgáltatás díját.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              3. Fizetési késedelem esetén Szolgáltató késedelmi kamatra
              jogosult. A késedelmi kamat mértéke a késedelemmel érintett
              naptári félév első napján érvényes jegybanki alapkamat 10
              százalékponttal növelt értéke. A felhasználói fizetési
              kötelezettség teljesítésének késedelme esetén a Szolgáltató a
              követelése behajtásával kapcsolatos költségei fedezetéül 40
              eurónak megfelelő, a Magyar Nemzeti Bank - késedelem kezdőnapján
              érvényes - hivatalos deviza-középárfolyama alapján meghatározott
              forint összegre (behajtási költségátalány) is igényt tarthat.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. Ha nem tudja használni a program hibái miatt a rendszert az
              első megrendelést követően, a szolgáltatás kiesési idejére
              pénzvisszafizetést biztosít Szolgáltató. Ezzel a Felhasználó akkor
              élhet ha bizonyítja, hogy a rendszer hibás vagy alkalmatlan a
              tevékenység gyakorlására.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              5. Szolgáltató folyamatos szolgáltatást nyújt a Felhasználók
              részére, amelyet egyszer kell megrendelni, tehát egyszer tölti ki
              Felhasználó a megrendelő lapot, ezután díjbekérőt kap a rendelt
              időszak díjáról. A kifizetett időszak lejárta előtt 10 nappal új
              díjbekérőt kap az új időszakra. Amennyiben Felhasználó csomagot,
              modult vagy fizetési ütemet szeretne váltani vagy eszközt
              vásárolni, úgy a díjbekérő levélen található csomagmódosító linken
              meg tudja tenni, így módosított díjbekérőt kap. Ha nem egyenlíti
              ki Felhasználó a díjbekérő összegét, akkor a kifizetett időszak
              utolsó napján megszűnik a szolgáltatás, Szolgáltató a fiókját
              törli a hozzáféréssel együtt és az előzményi adatok 10 nappal
              később elvesznek.
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_3dy6vkm"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                VII. A Szolgáltatás szezonális igénybevétele, szolgáltatás
                szüneteltetése, megtagadása
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              1
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató lehetőséget biztosít a Szolgáltatás
              igénybevételére, olyan Felhasználók részére is, akik szezonális
              jelleggel működnek, illetve a Szolgáltatást szezonális jelleggel
              használják.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2. A Felhasználónak lehetősége van évente egy alkalommal
              legfeljebb 6 hónapra a szolgáltatás szüneteltetését kérni. A
              szüneteltetés iránti igényt a Felhasználó a Szolgáltató felé
              kizárólag írásban e-mail üzenetben jelezheti.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              3. A szüneteltetés alatt a Felhasználó nem jogosult a Szolgáltatás
              használatára és nem köteles megfizetni a havi díjat sem. A
              szolgáltatás idő előtti esetleges újraindítását szintén email-ben
              kérheti. Az újraindítása az új időszak havi díjának befizetése
              után indul.{" "}
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. A szüneteltetés iránti kérelmet a Szolgáltató indoklás nélkül
              elutasíthatja.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Szolgáltatás megtagadása
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Amennyiben a Felhasználó ellen csőd-, felszámolási, vagy
              végelszámolási eljárás indul, a Szolgáltató jogosult újabb
              Szolgáltatás nyújtását megtagadni. A Szolgáltatás megtagadásával
              egyidejűleg a Szolgáltató jogosult azonnali hatállyal felmondani a
              Felhasználó valamennyi szerződését, azzal, hogy a teljesítés jelen
              pont szerinti megtagadása esetén a Szolgáltatót semmilyen
              kártérítési vagy szavatossági kötelezettség nem terheli.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Szolgáltató jogosult a Szolgáltatást a szerződés megszüntetése
              nélkül
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              egyoldalúan, részlegesen vagy teljesen felfüggeszteni abban az
              esetben is, ha Felhasználó olyan magatartást tanúsít, amely a
              jelen ÁSZF különös rendelkezései szerint egyébként azonnali
              hatályú felmondásra is okot adna.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A Szolgáltatás fenti okokból történő
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              részleges vagy teljes
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              &nbsp;
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              felfüggesztése, megszüntetése vagy a szerződés Szolgáltató általi
              felmondása nem mentesíti Felhasználót a megszüntetés vagy
              felmondás időpontja előtt, illetve a felfüggesztés időpontja előtt
              és/vagy alatt már igénybe vett Szolgáltatások díjának megfizetése
              alól.
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_1t3h5sf"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                VIII. Felelősségvállalás
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. Szolgáltató felelősséget vállal a Szolgáltatás megfelelő
              működéséért és üzemeltetéséért. A szolgáltatónak fel nem róható,
              illetve más Szolgáltató közreműködésével teljesített
              üzemzavarokért, hozzáférési hibákért a Szolgáltatót nem terheli
              felelősség. A szolgáltatás eléréséhez szükséges internet
              hozzáférés és az ahhoz szükséges eszközök biztosítása Felhasználó
              feladata.{" "}
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2. Szolgáltató nem vállal felelősséget a következő esetekben:
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              a) A Szolgáltatón kívül álló technikai és hardveres, esetleg
              szoftveres hibák, problémák esetén, beleértve a szerver működése
              és nem megfelelő kapcsolódása, internetes problémák, internetes
              szakadozások, bármiféle probléma, ami visszavezethető egy vagy
              több hardver nem megfelelő működésére, különösen a Felhasználónál
              található hardverre.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              b) A Felhasználónál működő internetes hálózat és internetes
              kapcsolódás hibájából eredő problémákért. Szolgáltató nem vállal
              felelősséget a rendszer az internetszolgáltató hibájából történő
              elérhetetlenségéért, lassú működéséért.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              c) A Felhasználó által használt olyan eszközök, gépek, nyomtatók,
              perifériák, pénztárgépek stb. használatából eredő problémákért,
              melyek a Szolgáltató rendszerével nem működnek megfelelően.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              d) A Felhasználó által az étlapra feltöltött képekért.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              e) Rendszerfrissítés esetén, ha a Felhasználó által használt
              elavult eszközön nem megfelelően működik a rendszer vagy teljesen
              megszűnik a rendszer működése.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              f) Vírusos támadás, DDOS és túlterhelési támadás, Trójai vírus és
              egyéb káros szoftver esetében, továbbá ismeretlen vagy ismert
              személyek digitális támadása esetén. Szolgáltató nem vállal
              felelősséget, ha külső támadás (pl. Hekkertámadás) éri az általa
              szolgáltatott alkalmazást, és ennek következtében adatvesztés vagy
              a szolgáltatás leállása történik. Ilyen esetekben Szolgáltató a
              lehető legrövidebb időn belül kijavítja az információs rendszer
              sérülésből keletkezett károkat és helyreállítja a szolgáltatást.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              g) Olyan magatartásból eredő problémáért vagy kárért, amely a
              Szolgáltatás szakszerűtlen, jogszerűtlen, szerződésellenes
              használatából ered.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              h) Olyan károkért vagy veszteségekért, amelyek személyes/bizalmas
              információk elvesztéséből, hardver/szoftver egészének vagy egy
              részének használhatatlanságából, személyi sérülésből, illetve
              bármely kötelezettség teljesítésének elmulasztásából származó
              bármilyen jellegű károkért vagy veszteségekért.{" "}
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              i) Olyan károkért vagy veszteségekért, amelyek a Szolgáltatás
              díjának vagy bármely más egyéb díjnak, költségnek (pl. bánatdíj) a
              Felhasználó által megadott bankkártyáról történő levonásából ered.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              j) A Szolgáltató felelőssége kizárt minden olyan magatartásért
              (különös tekintettel a károkra), amely a szolgáltatás
              szakszerűtlen, jogszerűtlen ÁSZF ellenes használatából ered,
              illetve a Felhasználó a szolgáltatás igénybevételével remélt
              anyagi hasznának a vártnál csekélyebb mértékéért, az anyagi haszon
              elmaradásáért/veszteségéért.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              3. A Weboldalon bemutatott információk, leírások, szövegek
              kizárólag a tájékozódás célját szolgálják és semmilyen körülmények
              között nem minősülnek szakmai tanácsadásnak, szakmai
              tájékoztatásnak, melyre tekintettel Szolgáltató nem vállal
              felelősséget azért, ha valamely Felhasználót vagy más személyt a
              weboldalon feltüntetett tartalom értelmezéséből eredően vagy az
              információk adott esetben történő felhasználásra nem megfelelősége
              miatt kár / veszteség ér.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A Weboldalon található adatok, információk, leírások, szövegek
              semmi esetre sem helyettesítik az egyedi esetben, egyénre
              (vállalkozásra) szabott tanácsadást, konzultációt.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. A Felhasználó köteles a Szolgáltatás igénybevételéhez, illetve
              díjfizetéshez kapcsolódó adatait, azok megváltozása esetén 5
              munkanapon belül a Szolgáltató felé bejelenteni. A Szolgáltató az
              adatbejelentés elmulasztása, vagy késedelmes teljesítése esetén
              kizárja a Szolgáltató késedelmét, és az ebből eredő
              többletköltséget a Felhasználó köteles megfizetni. Szolgáltató nem
              köteles meggyőződni arról, hogy a megrendelést, illetve a
              szerződést a Felhasználó részéről az arra jogosult személy írta
              alá. Az adatbejelentés elmulasztása vagy késedelmes bejelentése
              miatti számlázási késedelem esetén nem vállalja a Szolgáltatás
              folyamatos biztosítását.{" "}
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              5. A Felhasználó köteles tartózkodni olyan eszköz használatától,
              amely a Szolgáltatás üzemeltetéséhez használt szerverek leállását
              célozza, teszi lehetővé vagy azt eredményezheti, vagy amely
              egyébként veszélyezteti a Szolgáltatás rendeltetésszerű működését.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              6
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Felhasználó felelősséget vállal azért, hogy valamely
              kiegészítő csomag keretében bérelt eszközöket a szerződés
              megszűnése esetén haladéktalanul, rendeltetésszerű használatra
              alkalmas állapotban visszaadja a Szolgáltató részére. Amennyiben a
              szerződés időtartama alatt az eszköz megsérül, megsemmisül vagy
              egyéb módon használhatatlanná válik, az ezzel kapcsolatos
              felelősség teljes egészében a Felhasználót terheli.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              7
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Amennyiben a szerződés megszűnésekor a Felhasználó az
              eszközökkel nem tud elszámolni vagy sérülten adja azokat vissza a
              Szolgáltató részére, a Szolgáltató kárának megtérítésére tarthat
              igényt, melyet a Felhasználó köteles megfizetni.
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_4d34og8"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                IX. A szerződés megszüntetése/Felmondási jog
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              1
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Felek között a jogviszony határozatlan időre jön létre.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2.
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              {" "}
                        Felhasználó a Szolgáltatást lemondhatja. A lemondást követően –
              amennyiben a kifizetett időszak is letelt - a Felhasználó
              Szolgáltatáshoz való hozzáférése megszűnik. Fő csomag lemondása
              esetén a POS rendszerhez való hozzáférés megszűnik.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A szerződést a Szolgáltató jogosult bármikor 30 napos
              határidővel, indoklás nélkül írásban felmondani.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. A szerződés azonnali hatályú felmondására a másik fél súlyos
              szerződésszegése esetén van lehetőség. Az azonnali hatályú
              felmondást meg kell indokolni. Azonnali hatályú felmondás esetén a
              Felhasználó Szolgáltatáshoz való hozzáférése azonnal megszűnik.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              5
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Súlyos szerződésszegésnek minősül többek között, de nem
              kizárólagosan,
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              a) ha a Felhasználó fizetési késedelembe esik,
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              b) ha a Szolgáltató tudomására jut, hogy a Felhasználó
              valótlan/jogsértő adatokat adott meg a szerződéskötés során,
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              c) ha a Felhasználó Szolgáltatóval szembeni üzleti kommunikációja
              vagy magatartása súlyosan sértő, kifogásolható,
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              d) ha a Felhasználó ellen csőd-, felszámolási, vagy végelszámolási
              eljárás indul.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              6
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Szolgáltató jogosult a Szolgáltatást egyoldalúan felfüggeszteni
              és a hozzáférést megtagadni abban az esetben, ha a Felhasználó
              olyan magatartást tanúsít, amely azonnali hatályú felmondásra is
              okot adna.
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_2s8eyo1"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                X. Titoktartás
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              1
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató kötelezettséget vállal arra, hogy védi és őrzi,
              üzleti titokként bizalmasan kezeli a Szolgáltatás teljesítése
              során tudomására jutott adatokat, információkat, dokumentumokat és
              minden erőfeszítést megtesz annak érdekében, hogy azok megfelelő
              védelmét biztosítsák. Szolgáltató részére átadott valamennyi adat,
              információ üzleti titoknak minősül, mint ilyen bizalmasan
              kezelendő és harmadik személyek tudomására hozni tilos a
              Felhasználó írásbeli engedélye nélkül.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                    <a id="_17dp8vu"/>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2. A Szolgáltató és Felhasználó a bizalmas adatokat és
              információkat csak a Szolgáltatás teljesítése céljából
              használhatják fel, a tudomásukra jutott adatokat, információkat
              pedig csak a másik fél előzetes írásbeli hozzájárulásával
              hozhatják nyilvánosságra kivéve, ha a közlésre jogszabály kötelez.
              Jelen rendelkezés nem érinti a Szolgáltató azon jogát, hogy
              statisztikai adatokat (pl. forgalom, átlagos kiszállítási idő,
              népszerű ételek stb.) hozzon nyilvánosságra a Felhasználó
              beazonosíthatósága nélkül.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Felhasználót teljes körű felelősség terheli jelszavának
              titokban tartásával kapcsolatosan.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. A Szolgáltató a Felhasználó által rendelkezésére bocsátott
              személyes adatokat bizalmasan kezeli, kizárólag a Szolgáltatás
              teljesítéséhez használja fel.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              5
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Nem tekinthető a titoktartási kötelezettség megszegésének, ha
              bármelyik fél az erre illetékes hatóság, bíróság felhívására a
              hatósággal, bírósággal közli az üzleti titoknak, illetve
              bizalmasnak minősített információkat, adatokat.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              6. Felhasználó tudomásul veszi, hogy a jelen ÁSZF elfogadásával
              hozzájárul ahhoz, hogy Szolgáltató a Felhasználó esetleges
              fizetési késedelme vagy nem szerződésszerű teljesítése esetén a
              követelés behajtását harmadik félre bízza és neki a Felhasználó
              adatait átadja. Felhasználó kijelenti, hogy ezen eljárást nem
              tekinti az üzleti titok megsértésének, illetve a személyes
              adatokkal történő visszaélésnek.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              7. A Szolgáltató a Felhasználó által önkéntes adatszolgáltatás
              keretében rendelkezésére bocsátott személyes adatokat bizalmasan
              kezeli, kizárólag az egyes Felhasználók azonosításához, illetve a
              szolgáltatás teljesítéséhez, a sikeres teljesítéshez szükséges
              mértékben és az elektronikus kereskedelmi szolgáltatások, valamint
              az információs társadalommal összefüggő szolgáltatások egyes
              kérdéseiről szóló 2001. évi CVIII. törvény 13/A. §-ában
              meghatározottak szerint használja fel. A Felhasználó személyes
              adatainak jelen szerződésben meghatározott felhasználásához jelen
              Szerződés megkötésével egyben hozzájárulását adja.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              8. A Szolgáltató köteles a Felhasználó által a Szolgáltató
              rendszerbe rögzített adatokat a Felhasználó kérésére
              haladéktalanul törölni. Amennyiben a Felhasználó a törlést nem
              kéri, a szerződés megszűnését követően a Szolgáltató a Felhasználó
              által a Szolgáltató rendszerbe rögzített adatokat 30 napig
              tárolja.
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_3rdcrjn"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XI. Szavatosság/Jótállás/Hibás teljesítés
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              Felhasználó jelen szerződésben: Vállalkozásnak minősülő
              Felhasználó. Definíciója: olyan személy, aki a szakmája önálló
              foglalkozása vagy üzleti tevékenysége körében jár el.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              Hibás teljesítés: A kötelezett hibásan teljesít, ha a szolgáltatás
              a teljesítés időpontjában nem felel meg a szerződésben vagy
              jogszabályban megállapított minőségi követelményeknek. Nem
              teljesít hibásan a kötelezett, ha a jogosult a hibát a
              szerződéskötés időpontjában ismerte, vagy a hibát a szerződéskötés
              időpontjában ismernie kellett.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. A Felhasználó a Szolgáltató hibás teljesítése esetén a
              Szolgáltatóval szemben termék és vagy kellékszavatossági igényt
              érvényesíthet a Polgári Törvénykönyv szabályai szerint.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2. A Felhasználó kérhet kijavítást vagy kicserélést a
              Szolgáltatónál vásárolt Hardware eszközökre, kivéve, ha az ezek
              közül a Felhasználó által választott igény teljesítése lehetetlen
              vagy a Szolgáltató számára más igénye teljesítéséhez képest
              aránytalan többletköltséggel járna. Ha a Felhasználó a kijavítást
              vagy a kicserélést nem kérte, illetve nem kérhette, úgy
              igényelheti az ellenszolgáltatás arányos leszállítását, végső
              esetben - a szerződéstől is elállhat. Jelentéktelen hiba miatt
              elállásnak nincs helye.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              3. A Felhasználó választott kellékszavatossági jogáról egy másikra
              is áttérhet, az áttérés költségét azonban ő viseli, kivéve, ha az
              indokolt volt, vagy arra a vállalkozás adott okot.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. A Felhasználó köteles a hibát annak felfedezése után
              haladéktalanul közölni a Szolgáltatóval. A Felhasználó köteles
              bizonyítani, hogy a hiba már a teljesítés időpontjában is megvolt.
              A Felhasználó a szerződés teljesítésétől számított egy éves
              elévülési határidőn túl kellékszavatossági jogait már nem
              érvényesítheti.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              5. A 45/2014. (II.26.) Korm. rendelet szerinti 14 napos elállási
              jog csak a fogyasztókat illeti meg. Jelen szerződés vonatkozásában
              a Felhasználó az ÁSZF elfogadásával nyilatkozik, hogy nem minősül
              fogyasztónak, így az elállási jog őt nem illeti meg.
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_26in1rg"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XII. Szerzői jogok
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. A Weboldalon található valamennyi tartalom (a Weboldal grafikai
              megjelenése is) és a Szolgáltatótól weboldalról vásárolt
              valamennyi tartalom (beleértve Happy Gastro vendéglátós
              software/program rendszert) annak megjelenési formájától
              függetlenül szerzői jogi védelem alatt áll a szerzői jogról szóló
              1999. évi LXXVI. törvény alapján.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A szerzői jogi védelem alapján a szerzőnek kizárólagos joga van a
              mű egészének vagy valamely azonosítható részének anyagi formában
              és nem anyagi formában történő bármilyen felhasználására és minden
              egyes felhasználás engedélyezésére. Kifejezett írásbeli
              hozzájárulásával engedélyezi Szolgáltató.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A{" "}
            </span>
                    <a href="http://www.happygastro.hu" style={{textDecoration: "none"}}>
                        <u>
                <span
                    style={{
                        fontFamily: "Avenir",
                        color: "#212529",
                        backgroundColor: "#f7f8fc",
                    }}
                >
                  www.happygastro.hu
                </span>
                        </u>
                    </a>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              {" "}
                        weboldalról és annak adatbázisából bármilyen anyagot átvenni
              írásos hozzájárulás esetén is csak az adott weboldalra való
              hivatkozással lehet. A szolgáltató fenntartja minden jogát
              szolgáltatásának valamennyi elemére, a domain-neveire, az azokkal
              képzett másodlagos domain nevekre valamint az internetes
              reklámfelületeire.
            </span>
                </p>
                <p className="normal0">
                    Tilos a{" "}
                    <a href="http://www.happygastro.hu" style={{textDecoration: "none"}}>
                        <u>
                            <span style={{color: "#000000"}}>www.happygastro.hu</span>
                        </u>
                    </a>{" "} weboldal és az onnan a Szolgáltató által ellenszolgáltatás ellenében felhasználásra
                    engedélyezett Happy Gastro vendéglátós software bármely részéről másolatot készíteni, ill. annak
                    részeit adaptálni vagy visszafejteni, és tilos a felhasználói azonosítók és jelszavak
                    tisztességtelen módon történő létesítése, ill. bármely olyan alkalmazás használata amellyel
                    a{" "}
                    <a href="http://www.happygastro.hu" style={{textDecoration: "none"}}>
                        <u>
                            <span style={{color: "#000000"}}>www.happygastro.hu</span>
                        </u>
                    </a>{" "} weboldalon vagy azok bármely része módosítható vagy indexelhető.
                </p>

                <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              .{" "}
            </span> Kifejezetten tilos a Weboldalon található tartalmakról és a Szolgáltató által ellenszolgáltatás
                    ellenében felhasználásra engedélyezett Happy Gastro vendéglátós software bármely részéről
                    másolatot készíteni, azokat bármely módon átvenni, bármilyen módon sokszorosítani, nyilvánosan
                    bemutatni vagy közvetíteni, más helyen részben vagy egészben közzétenni, más módon való
                    felhasználása, elektronikus tárolása, feldolgozása és értékesítése. Más személyek nem
                    használhatják a Weboldalra feltöltött szövegeket, a Happy Gastro software program részeit a
                    saját termékükhöz, szolgáltatásukhoz. A Felhasználó a Weboldalon található tartalmakat és onnan
                    vásárolt vendéglátós software részeit vagy egészét – a térítés ellenében hozzáférhető tartalmat
                    is ide értve – nem értékesítheti tovább, saját termékeihez, szolgáltatásához nem használhatja,
                    azok alapján nem hozhat létre saját terméket vagy szolgáltatást.
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              3. Amennyiben a Szolgáltató felhasználói felületet biztosít, mely
              felületre a Felhasználó/Megrendelő felhasználónév és jelszó
              használatával beléphet, úgy annak a felületnek a hozzáférését
              Felhasználó/Megrendelő nem oszthatja meg másokkal, amennyiben
              mégis megosztja és ezzel ő vagy a harmadik személy a
              Szolgáltatónak kárt okoz, úgy Felhasználó / Megrendelő köteles a
              Szolgáltatónak az ezzel okozott kárt megtéríteni.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              4
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Felhasználó tudomásul veszi, hogy a felhasználási engedély
              nélküli felhasználás esetén Szolgáltatót kötbér illeti meg. A
              kötbér összege képenként bruttó 60.000.-Ft, illetve szavanként
              bruttó 20.000.-. A szerzői jogok megsértéséért, az oldal
              másolásáért a Felhasználó 2.000.000,-Ft+ÁFA, azaz kétmillió forint
              + ÁFA kötbért köteles fizetni a Szolgáltatónak oldalanként,
              amelyről Szolgáltató kötbér számlát állít ki a jogsértő részére.
              Felhasználó tudomásul veszi, hogy ezen kötbérkikötés nem túlzó, és
              ennek tudatában használja az oldalt. A szerzői jogi jogsértés
              esetén Szolgáltató közjegyzői ténytanúsítást alkalmaz, melynek
              összegét szintén a jogsértő felhasználóra hárítja.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A Szolgáltató, illetve a szerző a jogsértő személlyel szemben a
              kötbért meghaladó kárát is jogosult követelni, kárigényét vele
              szemben érvényesíteni.
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_lnxbz9"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XIII. Jogi nyilatkozat
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. A Szolgáltató kizár minden felelősséget a Weboldal és a
              Szolgáltatás használói által tanúsított magatartásért. Amennyiben
              jogsértés merül fel a Szolgáltató együttműködik az eljáró
              hatóságokkal a felderítés végett.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató nem vállal felelősséget a szerver esetleges
              meghibásodásából, a Weboldal tartalmának harmadik fél általi
              megváltoztatásából származó károkért.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Tilos a Weboldalon megjelenő tartalmak, így különösen a
              blogbejegyzések vagy azok egy részének másolása vagy más módon
              való felhasználása, feldolgozása és értékesítése a Szolgáltató
              írásos hozzájárulása nélkül.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              4
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Felhasználó a Szolgáltatás megrendelése során köteles a saját,
              valós adatait megadni. Szolgáltató kizárja felelősségét,
              amennyiben Felhasználó más nevében, más személy adataival veszi
              igénybe szolgáltatásait. A Szolgáltatót a Felhasználó által
              tévesen és/vagy pontatlanul megadott adatokra visszavezethető
              késedelemért, illetve egyéb problémáért, hibáért semminemű
              felelősség nem terheli.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              5
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Amennyiben a Szolgáltató minden gondossága ellenére hibás ár
              kerül a Weboldal felületére, különös tekintettel a nyilvánvalóan
              téves, például a Szolgáltatás közismert, általánosan elfogadott
              vagy becsült árától jelentősen eltérő, esetleg rendszerhiba miatt
              megjelenő 0,-Ft-os vagy 1,-Ft-os árra, akkor a Szolgáltató nem
              köteles a Szolgáltatást hibás áron értékesíteni, hanem
              felajánlhatja a helyes áron történő szolgáltatásnyújtást, amelynek
              ismeretében a Felhasználó elállhat megrendelési szándékától.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              6
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Szolgáltató a szerzői jog jogosultja a honlapon keresztül
              elérhető, Szolgáltatások nyújtása során megjelenített valamennyi
              tartalomnak: bármely szerzői műnek, illetve más szellemi
              alkotásnak (ideértve valamennyi elkészíttetett grafikát, képet,
              videót, fotót és a honlap felületének elrendezését, ötleteket,
              megvalósításokat).
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              7. Szolgáltató fenntart magának minden jogot a Weboldal, annak
              bármely részlete és az azon megjelenő tartalmak tekintetében.
              Tilos a Weboldalon megjelenő tartalmak vagy azok bármely
              részletének letöltése, elektronikus tárolása, feldolgozása és
              értékesítése a Szolgáltató írásos hozzájárulása nélkül.
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_35nkun2"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XIV. Vis Maior
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              Vis maiornak minősül minden olyan rendkívüli, a szerződés
              létrejötte után bekövetkező, annak teljesítését lehetetlenné tevő
              esemény, amelyet szerződő felek nem láthattak előre, és nem
              háríthattak el, és amely nem vezethető vissza egyik fél felróható
              magatartására sem. Ilyen események lehetnek különösen:
              szükségállapot, sztrájk, háború, forradalom, terrorista
              cselekmények, természeti katasztrófa, tűzvész, árvíz, járvány,
              karantén korlátozások, szállítási embargó stb. Vis maior esetén
              mindkét fél mentesül a szerződés teljesítésének kötelezettsége
              alól.
            </span>
                </p>


                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_1ksv4uv"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XV. Adatvédelem
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A weboldal adatkezelési tájékoztatója elérhető a következő
              oldalon: www.happygastro.hu/adatvédelem
            </span>
                </p>


                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_1ksv4uv"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XVI. ELÁLLÁS JOGA
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
                <ul>
                    <li>1. Az Európai Parlament és a Tanács 2011/83/EU számú irányelvének, továbbá a fogyasztó és a vállalkozás közötti szerződések részletes szabályairól szóló 45/2014. (II.26.) Korm. rendelet szabályozása értelmében a fogyasztó a megrendelt termék kézhez vételétől számított 14 napon belül indokolás nélkül elállhat a szerződéstől, visszaküldheti a megrendelt terméket.</li>

<li>2. Az elállási jog nem illeti meg a Felhasználót, ha az vállalkozás, azaz a jogi személy, továbbá az olyan személyt, aki a szakmája, önálló foglalkozása vagy üzleti tevékenysége körében jár el!</li>
<li>3. Az elállási jog gyakorlására nyitva álló idő attól a naptól számított 14 nap elteltével jár le, amelyen Felhasználó, vagy az általa megjelölt vagy üzletkörében képviselőként eljáró, a fuvarozótól eltérő harmadik személy a terméket átveszi.</li>
                </ul>
            </span>
                </p>


                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_44sinio"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XVII. Panaszügyintézés és jogérvényesítés{" "}
              </span>
                    </strong>
                </p>
                <p className="normal0">
                    <span style={{fontFamily: "Avenir"}}>1. </span>
                    <span style={{fontFamily: '"Helvetica Neue"', fontSize: "11pt"}}>
              Panaszügyintézés és jogérvényesítés{" "}
            </span>
                </p>
                <p className="normal0">
                <span style={{fontFamily: '"Helvetica Neue"', fontSize: "11pt"}}>
              1. A Felhasználó kifogásait a Szolgáltató felé e-mail üzenetben
              terjesztheti elő az{" "}
            </span>
                    <a href="mailto:info@Happygastro.hu" style={{textDecoration: "none"}}>
                        <u>
                <span
                    style={{
                        fontFamily: '"Helvetica Neue"',
                        fontSize: "11pt",
                        color: "#000000",
                    }}
                >
                  info@Happygastro.hu
                </span>
                        </u>
                    </a>
                    <span style={{fontFamily: '"Helvetica Neue"', fontSize: "11pt"}}>
              {" "}
                        e-mail címen. A Szolgáltató a felhasználói kifogásokra legkésőbb
              30 napon belül válaszol. Szolgáltató a szóbeli panaszt azonnal
              megvizsgálja és szükség esetén orvosolja. Ha a Felhasználó a
              panasz kezelésével nem ért egyet vagy a panasz azonnali
              kivizsgálása nem lehetséges a Szolgáltató a panaszról és az azzal
              kapcsolatos álláspontjáról haladéktalanul jegyzőkönyvet vesz fel s
              annak egy másolati példányát átadja a Felhasználónak.{" "}
            </span>
                </p>
                <p className="normal0" style={{fontSize: "11pt"}}>
                <span style={{fontFamily: '"Helvetica Neue"'}}>
              2. Amennyiben a Szolgáltató és a Felhasználó között esetlegesen
              fennálló jogvita nem rendeződik, a Felhasználó bírósághoz
              fordulhat. A Felek a közöttük létrejött szerződéses jogviszonyból
              fakadó bármely jogvita esetére alávetik magukat
            </span>
                    <strong>
                        <span style={{fontFamily: '"Helvetica Neue"'}}> </span>
                    </strong>
                    <span style={{fontFamily: '"Helvetica Neue"'}}>
              – hatáskörtől függően – Pesti Központi Kerületi Bíróság vagy a
              Fővárosi Törvényszék illetékességének.
            </span>
                </p>
                <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                    <a id="_2jxsxqh"/>
                    <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XVIII. Egyéb rendelkezések
              </span>
                    </strong>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. A Szolgáltató és a Felhasználó közötti jogviszonyra a magyar
              jog az irányadó.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Jelen ÁSZF elektronikus formában és kizárólag magyar nyelven
              kerül megkötésre. Az így létrejött szerződés nem minősül írásba
              foglalt szerződésnek és nem kerül iktatásra.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Szolgáltató kötelezettsége teljesítéséhez közreműködőt jogosult
              igénybe venni, melynek esetleges jogellenes magatartásáért teljes
              felelősséggel tartozik, úgy mintha a jogellenes magatartást saját
              maga követte volna el.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              4
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Jelen ÁSZF-et a Szolgáltató indokolás nélkül bármikor
              módosíthatja, erről a Felhasználót a Weboldalon keresztül
              értesíti. Amennyiben a Felhasználó az ÁSZF módosítást nem fogadja
              el, úgy a szerződése azonnali hatállyal megszüntetésre kerül és a
              továbbiakban nem jogosult a Szolgáltatás használatára.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              5
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A jelen ÁSZF-ben nem szabályozott kérdésekben a Polgári
              Törvénykönyv és az egyéb irányadó jogszabályok rendelkezéseit kell
              alkalmazni.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              6
            </span>
                    <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Weboldal használatával, továbbá megrendelés leadásával és a
              Szolgáltatás használatával a Felhasználó elfogadja az ÁSZF
              tartalmát.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              7.Ha a jelen Szabályzat bármely része érvénytelenné, jogtalanná
              vagy érvényesíthetetlenné válik, az a fennmaradó részek
              érvényességét, jogszerűségét és érvényesíthetőségét nem érinti.
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              8.Amennyiben Szolgáltató a Szabályzat alapján megillető jogát nem
              gyakorolja, a joggyakorlás elmulasztása nem tekinthető az adott
              jogról való lemondásnak. Bármilyen jogról történő lemondás csak az
              erre vonatkozó kifejezett írásbeli nyilatkozat esetén érvényes. Az
              hogy a Szolgáltató egy alkalommal nem ragaszkodik szigorúan a
              Szabályzat valamely lényegi feltételéhez, vagy kikötéséhez nem
              jelenti azt, hogy lemond arról, hogy a későbbiekben ragaszkodjon
              az adott feltétel vagy kikötés szigorú betartásához.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              9. Szolgáltató és Felhasználó vitás ügyeiket békés úton próbálják
              rendezni.
            </span>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              10. Felek rögzítik, hogy a Szolgáltató weboldala/mobil
              applikációja Magyarországon működik, karbantartását is itt végzik.
              Mivel az oldal más országokból is meglátogatható, ezért a
              felhasználók kifejezetten tudomásul veszik, hogy a felhasználó és
              a Szolgáltató viszonylatában az irányadó jog a magyar jog.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              11. A jelen Szabályzatban nem szabályozott kérdésekre, valamint
              jelen Szabályzat értelmezésére a magyar jog az irányadó, különös
              tekintettel a Polgári Törvénykönyvről szóló 2013. évi V. törvény
              („Ptk.”) és az elektronikus kereskedelmi szolgáltatások, az
              információs társadalommal összefüggő szolgáltatások egyes
              kérdéseiről szóló 2001. évi CVIII. (Elker. tv.) törvény. A
              vonatkozó jogszabályok kötelező rendelkezései a felekre külön
              kikötés nélkül is irányadók.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              12. A jelen Szabályzat{" "}
            </span>
                    <strong>
              <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
                2023. július 1
              </span>
                    </strong>
                    <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              . napjától hatályos és visszavonásig hatályban marad. A
              Szolgáltató jogosult egyoldalúan módosítani a Szabályzatot (a
              módosításra okot adó körülmények: jogszabály-változás, üzleti
              érdek, céggel kapcsolatos változások), mely módosítás esetén a
              Felhasználónak joga van a szerződéstől elállni, vagy azt
              felmondani.
            </span>
                </p>
                <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2024.04.15
            </span>
                </p>
                <div style={{clear: "both"}}>
                    <p className="normal0">&nbsp;</p>
                    <p className="normal0">&nbsp;</p>
                    <p className="normal0">&nbsp;</p>
                </div>
            </div>
        </div>
    }
    return (
        <Layout>
            <SEO title="ÁSZF"/>
            <div className={"aszf-container"}>
                <div>
                    <p className="normal0" style={{marginBottom: "36pt", textAlign: "center"}}>
                <span style={{fontSize: 28, color: "#212529",}}>
                          Általános Szerződési Feltételek
                        </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid"}}></p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                <span style={{fontFamily: '"Helvetica Neue"'}}>
                  I. Bevezető ÁSZF Célja
                </span>
                        <span style={{width: "340.38pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_gjdgxs" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                2
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_gjdgxs" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              II. Szolgáltató adatai
            </span>
                        <span style={{width: "358.67pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_30j0zll" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                2
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_30j0zll" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              III. A Szolgáltatás
            </span>
                        <span style={{width: "372.65pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_1fob9te" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                2
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_1fob9te" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              IV. Felek közötti szerződéses jogviszony létrejövetele
            </span>
                        <span style={{width: "172.44pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_3znysh7" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                4
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_3znysh7" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              V. Fizetési feltételek, bankkártya regisztráció
            </span>
                        <span style={{width: "220.68pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_2et92p0" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                6
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_2et92p0" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              VI. Számlázás és fizetési késedelem és pénzvisszafizetés
            </span>
                        <span style={{width: "156.54pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_tyjcwt" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                7
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_tyjcwt" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              VII. A Szolgáltatás szezonális igénybevétele, szolgáltatás
              szüneteltetése, megtagadása
            </span>
                        <span style={{width: "36pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_3dy6vkm" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                7
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_3dy6vkm" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              VIII. Felelősségvállalás
            </span>
                        <span style={{width: "341.94pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_1t3h5sf" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                8
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_1t3h5sf" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              IX. A szerződés megszüntetése/Felmondási jog
            </span>
                        <span style={{width: "202.36pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_4d34og8" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                10
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_4d34og8" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              X. Titoktartás
            </span>
                        <span style={{width: "390.14pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_2s8eyo1" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                11
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_2s8eyo1" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              XI. Szavatosság/Jótállás/Hibás teljesítés
            </span>
                        <span style={{width: "242.78pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_3rdcrjn" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                12
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_3rdcrjn" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              XII. Szerzői jogok
            </span>
                        <span style={{width: "364.1pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_26in1rg" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                13
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_26in1rg" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              XIII. Jogi nyilatkozat
            </span>
                        <span style={{width: "347.77pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_lnxbz9" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                14
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_lnxbz9" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              XIV. Vis Maior
            </span>
                        <span style={{width: "378.86pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_35nkun2" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                15
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_35nkun2" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              XV. Adatvédelem
            </span>
                        <span style={{width: "366.04pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_1ksv4uv" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                15
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_1ksv4uv" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              XVI. Panaszügyintézés és jogérvényesítés
            </span>
                        <span style={{width: "231.53pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_44sinio" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                15
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{marginTop: "8pt", fontSize: "14pt"}}>
                        <a href="#_44sinio" style={{textDecoration: "none"}}/>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              XVII. Egyéb rendelkezések
            </span>
                        <span style={{width: "312.81pt", fontFamily: '"Helvetica Neue"', display: "inline-block",}}>
              &nbsp;
            </span>
                        <a href="#_2jxsxqh" style={{textDecoration: "none"}}>
              <span
                  style={{fontFamily: '"Helvetica Neue"', color: "#000000"}}
              >
                15
              </span>
                        </a>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid"}}>
                        <br style={{pageBreakBefore: "always", clear: "both"}}/>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_gjdgxs"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                I. Bevezető ÁSZF Célja
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. Jelen Általános Szerződési Feltételek (továbbiakban: ÁSZF)
              célja, hogy összefoglalja a Happy Gastro Kft. (Továbbiakban:
              Szolgáltató) által a{" "}
            </span>
                        <a href="http://www.happygastro.hu" style={{textDecoration: "none"}}>
                            <u>
                <span
                    style={{
                        fontFamily: "Avenir",
                        color: "#212529",
                        backgroundColor: "#f7f8fc",
                    }}
                >
                  www.happygastro.hu
                </span>
                            </u>
                        </a>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              {" "}
                            domain név alatt üzemeltetett weboldalon (továbbiakban: weboldal)
              keresztül elérhető szolgáltatást megrendelő személy (a
              továbbiakban: Felhasználó) között létrejövő szerződés tartalmát,
              az igénybevétel feltételeit, a felek jogait és kötelezettségeit,
              továbbá a vonatkozó jogszabályok rendelkezéseit.{" "}
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Felhasználó a szolgáltatás megrendelése előtt köteles
              megismerni a jelen ÁSZF rendelkezéseit. A szolgáltatás
              megrendelésével, használatával a Felhasználó elfogadja a jelen
              ÁSZF rendelkezéseit.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              3. A mindenkor aktuális ÁSZF folyamatosan elérhető a{" "}
            </span>
                        <a href="http://www.happygastro.hu/aszf" style={{textDecoration: "none"}}>
                            <u>
                <span
                    style={{
                        fontFamily: "Avenir",
                        color: "#212529",
                        backgroundColor: "#f7f8fc",
                    }}
                >
                  www.happygastro.hu/aszf
                </span>
                            </u>
                        </a>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              {" "}
                            weboldal helyen.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. Jelen ÁSZF tárgyi hatálya kiterjed a Happy Gastro Kft. És
              weboldalán elérhető ill. hozzáférhető szolgáltatások
              (továbbiakban: szolgáltatás) igénybevételére, valamint azzal
              összefüggő lényeges feltételekre. Személyi hatálya kiterjed
              valamennyi a weboldalt látogató és a vállalkozó által nyújtott
              bármely szolgáltatást igénybe vevő Felhasználóra, vállalkozóra.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              5. A Szolgáltatás használatának megkezdésével a Felhasználó és a
              Szolgáltató között online szerződés jön létre, a jelen
              Szabályzatban foglalt feltételekkel. Szolgáltató fenntartja a
              jogot, hogy a Happy Solutions weboldal / mobil alkalmazás /software
              bármely tartalmi elemét bármikor előzetes figyelmeztetés nélkül
              módosítsa vagy megszüntesse, azok megjelenését, tartalmát,
              működését megváltoztassa vagy hozzáférhetőségét a jelen ÁSZF-ben
              foglaltak betartásával megszüntesse
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_30j0zll"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                II. Szolgáltató adatai
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "2pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Név:
            </span>
                        <span style={{
                            width: "12.67pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{
                            width: "36pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{
                            width: "36pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Happy Gastro Kft
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "2pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Székhely:
            </span>
                        <span style={{
                            width: "24.68pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{
                            width: "36pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1034 Budapest, Bécsi út 89-91. fsz.1.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "2pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Cégjegyzékszám:
            </span>
                        <span style={{
                            width: "23.37pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              01-10-041037
            </span>
                    </p>
                    <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Adószám:
            </span>
                        <span style={{
                            width: "23.34pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{
                            width: "36pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              28735636-1-41
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Telefonszám:
            </span>
                        <span style={{
                            width: "6.7pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{
                            width: "36pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              +36-1-300-91-05
            </span>
                        <br/>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Weblap:{" "}
            </span>
                        <span style={{
                            width: "28.35pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{
                            width: "36pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              www.happygastro.hu
            </span>
                        <br/>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              E-mail:{" "}
            </span>
                        <span style={{
                            width: "33.01pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{
                            width: "36pt",
                            fontFamily: "Avenir",
                            backgroundColor: "#f7f8fc",
                            display: "inline-block",
                        }}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              info@happygastro.hu
            </span>
                        <br/>
                        <span style={{fontFamily: "Avenir", color: "#212529"}}>
              &nbsp;
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_1fob9te"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                III. A Szolgáltatás
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              1
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Az “Happy Gastro” egy éttermi rendszer (a továbbiakban:
              Szolgáltatás), mely vendéglátó egységek működését segíti:
              rendelések kezelésével mind a la carte, kioszk, elviteles
              formában, házhozszállításos rendelések kezelése futárokkal és
              külső integrációkkal, jelentések elkészítésével stb. A
              Szolgáltatáshoz tartozik a weboldal, az ehhez kötődő
              háttérszerverek és minden olyan program, szoftver vagy hardver,
              ami a weboldal háttérszervereihez csatlakozik, adatot kér le és a
              Szolgáltató tulajdonát képezi, beleértve az egyéb csatornákat
              (digitális, offline), ami a Szolgáltatás működését segíti,
              támogatja vagy függ tőle.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató három fő csomagot kínál a Felhasználók részére. A
              „Mini csomag” azoknak a vendéglátó helyeknek praktikus, akik a
              kisebb méret és használat miatt nincs szüksége több funkciókra. Az
              „Alap csomag” a közepes és nagy vendéglátó egységeknek hasznos,
              akiknek fő profilja a beltéri asztalos kiszolgálás és/vagy a
              kiszállítás. A „Prémium csomag” a nagyobb funkcionalitást igénylő
              vendéglátó egységeknek hasznos, ami tartalmilag az Alap csomag
              kiegészítve további kiegészítő csomagokkal. A Felhasználó a három
              fő csomag egyikére tud előfizetni. A Felhasználó amennyiben egyik
              csomag sem felel meg a vállalkozásának úgy egyénre szabott
              csomagot is össze tud saját maga számára állítani a honlapon.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató lehetőséget biztosít ún. kiegészítő csomagok
              igénybevételére. Az aktuálisan elérhető kiegészítő csomagokról és
              azok díjáról a Felhasználó a Weboldalon tud tájékozódni.
              Kiegészítő csomag kizárólag valamelyik fő csomagra („Mini csomag”,
              „Alap csomag”, „Prémium csomag” vagy “Egyéni csomag”) történő
              előfizetés mellett igényelhető. A szolgáltatásokon túl Szolgáltató
              biztosít Hardware eszközöket és kiegészítő
              termékeket/szolgáltatásokat is melyeknek ára és pontos termék
              leírása a honlapon megtalálható.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              4
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A szolgáltatások részleteiről és díjáról a Felhasználó a
              Weboldalon tájékozódhat. A megjelenített árak forintban értendők
              és az Általános Forgalmi Adó (ÁFA) összegét nem tartalmazzák,
              eszközönként értendők, havi díjak és előre fizetendők. Kivéve
              Hardware és Hardware support mely alkalmi díjas és nem havi
              díjazású.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              5
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató fenntartja magának a jogot a Szolgáltatás
              funkcióinak és különféle szolgáltatásainak korlátozására,
              változtatására, levételére és hozzáadására. A Felhasználó
              kifejezetten elfogadja, hogy nem minden esetben ugyanazokat a
              funkciókat és szolgáltatásokat láthatja és használhatja, mint más
              Felhasználók. A Felhasználó ezzel szemben semmilyen jogcímen nem
              támaszthat követelést vagy igényt a Szolgáltatóval szemben.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              6
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató a Felhasználó részére kizárólag hozzáférést
              biztosít a Rendszerhez, annak tulajdonát nem ruházza át, az a
              Szolgáltató tulajdonában marad.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              7. A Felhasználó jogosult alkalmazottat is kijelölni a
              Szolgáltatás használatára. Ezt meghaladóan a Felhasználó a
              Szolgáltatást csak maga használhatja, harmadik személynek ahhoz
              hozzáférést nem biztosíthat. Felhasználó a használati jogát nem
              ruházhatja át harmadik személynek sem ingyenesen, sem
              visszterhesen.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              8. A Weboldalon történő regisztrációval a Felhasználó elismeri,
              hogy a Szolgáltatást gazdasági tevékenységének körében és nem
              fogyasztóként használja, és a saját vagy az általa képviselt
              gazdálkodó szervezet nevében regisztrál.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              9. A Szolgáltató a Felhasználó csatlakozási szándékát jogosult
              indokolás nélkül elutasítani.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              10. Amennyiben a weboldalon hiba vagy hiányosság lép fel a
              termékeknél/szolgáltatásoknál vagy az áraknál, fenntartjuk a jogot
              a korrekcióra. Ilyen esetben a hiba felismerése illetve módosítása
              után azonnal tájékoztatjuk a vevőt az új adatokról. Felhasználó
              ezt követően még egyszer megerősítheti a rendelést vagy elállhat a
              szerződéstől.{" "}
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_3znysh7"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                IV. Felek közötti szerződéses jogviszony ix
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "1pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. Érdeklődés/Regisztráció
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A Felhasználó regisztráció után az “ajánlat kérése” gombra
              kattintva kifejezheti érdeklődését a Szolgáltatással kapcsolatban
              annak érdekében, hogy vele a Szolgáltató a szerződés megkötése
              érdekében felvegye a kapcsolatot. A jelentkezés során a
              Felhasználónak meg kell adnia a cég vagy egyéni vállalkozás és az
              étterem nevét, adószámát, valamint kapcsolattartói adatokat (név,
              telefonszám, e-mail cím), továbbá, hogy kiszállítós (és/vagy)
              helyben értékesítő éttermet üzemeltet és várhatóan mennyi eszközre
              lesz szüksége, megvásárolni kívánt szolgáltatást és azok
              darabszámát (ahol lehet, beállítja). Továbbá el kell fogadja az
              ÁSZF-t.
            </span>
                    </p>
                    <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Demo
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A regisztrációval a Felhasználó 3 napos demó hozzáférést kap a
              kiválasztott fő csomagra emailen. Ezen időszak alatt a Felhasználó
              feltöltheti étterme adatait vagy külön díj ellenében megbízhatja a
              Szolgáltatót ezzel a feladattal. A demó időszak alatt a
              Szolgáltatás bármikor díjmentesen lemondható. A demó időszak
              letelte után a Felhasználó részére kiterhelésre kerül a havidíj és
              megkezdődik a szerződéses jogviszony. A feltöltött adatokat az
              éles rendszer átveszi, nem szükséges azt még egyszer feltölteni.
            </span>
                    </p>
                    <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Szerződéskötés
            </span>
                    </p>
                    <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              A Felhasználó ajánlat kérésére egy automatikusan generált
              visszaigazoló email érkezik. Felhasználó tudomásul veszi, hogy
              ezen automatikus visszaigazolással szerződés nem keletkezett.
              Szerződés akkor jön létre, amikor emailen érkező ajánlatot
              (melyben a megrendelés részletei) Felhasználó a megrendelésével
              visszaigazolja. Innentől fizetési kötelezettsége keletkezik.
            </span>
                    </p>
                    <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              Szolgáltató kizárja a visszaigazolási felelősséget, ha a
              visszaigazolás azért nem érkezik meg időben, mert Felhasználó
              rossz e-mail címet adott meg a regisztrációja során, vagy a
              fiókjához tartozó tárhely telítettsége miatt nem tud üzenetet
              fogadni. Megrendelő, Felhasználó a megrendelési folyamat lezárása
              előtt minden esetben vissza tud lépni az előző fázisba és a bevitt
              adatait javítani tudja.
            </span>
                    </p>
                    <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              A Felhasználó megadott email címére majd a következő generált
              email már az ajánlattétel - írásban véglegesíti a Felhasználóval a
              megrendelés tartalmát, mely elfogadására oly módon van lehetőség,
              hogy a megjelölt link a honlapra vezeti, ahol befejezheti
              Felhasználó a vásárlást. Felhasználó a megrendeléssel tudomásul
              veszi, hogy fizetési kötelezettsége keletkezik. Miután megrendelte
              és a honlapon kifizette előre a kívánt szolgáltatást,{" "}
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              ezt követően e-mailben megkapja a Szolgáltatás igénybevételéhez a
              felhasználónevet és a jelszót. A Felhasználó csak abban az esetben
              tudja használni a Szolgáltatást, ha elfogadja az Általános
              Szerződési Feltételeket és megadja bankkártya adatait a havidíj
              fizetéséhez, továbbá legalább 1 havi díjat kifizetett az online
              felületen bankkártyával.
            </span>
                    </p>
                    <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3.
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              {" "}
                            Adatfeltöltés
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A szerződéskötést követő hét napon belül a Szolgáltató feltölti az
              étterem adatait, amennyiben külön díj ellenébe megrendelte és a
              vendéglátó egység nem maga tölti fel.{" "}
            </span>
                    </p>
                    <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. Szállítási feltételek - Hardware eszközök vétele
            </span>
                    </p>
                    <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Hardware eszközök rendelésekor a fizetendő végösszeget
              kiegyenlítése után, Szolgáltató beszerzi és feladja az eszközt
              csomag formájában amennyiben nem tudja a helyszínre szállítani. A
              csomag tartalmazza az eszközt/eszközöket, a számlát és a jótállási
              jegyet. A felhasználó köteles a csomagot kézbesítéskor a futár
              előtt megvizsgálni és termékeken csomagolás észlelt esetleges
              sérülés esetén köteles jegyzőkönyv felvételét kérni, sérülés
              esetén a csomagot nem köteles átvenni. Utólagos jegyzőkönyv
              nélküli reklamációt Szolgáltató nem fogad el!{" "}
            </span>
                    </p>
                    <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              5. Technikai feltételek, hibakezelés
            </span>
                    </p>
                    <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A technikai feltételeket honlapunkon közöljük, mely tartalmazza a
              Happy Gastro software helyes működéséhez a Hardware igényt.{" "}
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              Szolgáltató a megrendelőnek a szolgáltatás gyorsasága érdekében
              felajánlhatja az adatok archiválását, amit ha nem fogad el a
              Felhasználó, akkor számolnia kell vele, hogy a rendszer lassabb
              lehet.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              Ügyfélszolgálatunk munkanapokon 10-18h fogadja a hívást a{" "}
            </span>
                        <strong>
              <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
                06-1-300-91-05
              </span>
                        </strong>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              -ös telefonszámon, vagy ha Felhasználó
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              admin felületen kitölt egy hibajegyet az ügyfélszolgálati űrlapon
              vagy emailt küld a
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                        <strong>
              <span style={{fontFamily: "Avenir"}}>
                support@happygastro.hu
              </span>
                        </strong>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              címre, akkor vállaljuk, hogy egy munkanapon belül válaszol
              Szolgáltató.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              6. A szolgáltatási díjak
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              A szolgáltatások mindenkori díjszabása a weboldalon
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;{" "}
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              található. A díjakat a Felhasználó kizárólag a Szolgáltató által
              biztosított fizetési módokat használva egyenlítheti ki. A
              Szolgáltató jogosult a Szolgáltatási Díjakat
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              minden előzetes értesítés nélkül is egyoldalúan módosítani.{" "}
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt"}}>
                <span style={{fontFamily: '"Helvetica Neue"'}}>
              A Felhasználó jogosult a jövőre nézve, a még nem esedékes, és meg
              nem fizetett szolgáltatások tekintetében jelen szerződés
              felmondására.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              A Szolgáltató főszabályként elektronikus számlát bocsát ki a
              befizetésekről.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              Ha külső tényező miatt a szerződés teljesítése lehetetlenné válik,
              és Felhasználó már kifizette a díjat
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              a szerződés megszűnik.
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              A teljesítés lehetetlenné válásáról tudomást szerző fél késedelem
              nélkül köteles erről a másik felet értesíteni. Az értesítés
              elmulasztásából eredő kárt a mulasztó fél köteles megtéríteni.
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              Ha a teljesítés lehetetlenné válásáért egyik fél sem felelős, a
              szerződés megszűnésének időpontját megelőzően nyújtott
              szolgáltatás pénzbeli ellenértékét meg kell téríteni. Ha a már
              teljesített pénzbeli szolgáltatásnak megfelelő ellenszolgáltatást
              a másik fél nem teljesítette, a pénzbeli szolgáltatás visszajár.
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_2et92p0"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                V. Fizetési feltételek, bankkártya regisztráció
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              1
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltatás igénybevételéhez a Felhasználó köteles a
              bankkártya adatokat megadni. Amennyiben nem áll rendelkezésre
              bankkártya adat, a Felhasználó a Szolgáltatás használatára nem
              jogosult. A Felhasználó több bankkártyát is tud regisztrálni,
              ebben az esetben ki kell jelölnie az elsődleges bankkártyát.
              Elsődleges kártyát nem lehet törölni és legalább egy bankkártyának
              mindig regisztrálva kell lennie.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2. A bankkártya regisztrálásakor a Szolgáltató 100,-Ft-ot zárol a
              bankkártyán. A zárolás a regisztráció befejezésével automatikusan
              feloldásra kerül.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Felhasználó a Szolgáltatás díját mindig előre fizeti,
              használatára akkor jogosult, ha a havidíjat megfizette. A
              Szolgáltató dönthet úgy, hogy nem vonja le, vagy nem számlázza ki
              a havidíjat, ilyenkor a Felhasználó a Szolgáltató által megszabott
              ideig ingyenesen használhatja a Szolgáltatást. Szolgáltató dönthet
              úgy is, hogy egy vagy több Felhasználó részére ideiglenesen
              ingyenesen biztosít egy csomagot. Ezt a döntését előzetes
              tájékoztatás nélkül bármikor visszavonhatja. A felhasználó akár
              több hónapot is előfizethet, de legalább 1 havi díjat szükséges
              előfizetni a szolgáltatás megkezdéséhez.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              4
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A havidíjat a Felhasználó által a Stripe oldalán megadott
              bankkártyáról automatikusan levonja a rendszer. A havidíj
              kiterhelésével a Felhasználó 1 tárgyhónap használati időre
              jogosult a Szolgáltatás használatára. A következő időszakra akkor
              történik meg az automatikus díjlevonás, amikor a használati idő 10
              napra csökken.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              5
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Amennyiben a rendszer nem tudja levonni az adott napon a
              Felhasználó által megadott bankkártyáról az összeget, akkor a
              rendszer automatikusan minden nap megpróbálja levonni az összeget,
              annak sikerességéig. Sikeresség esetén 30 nap használati idő
              jóváírásra kerül.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              6. A Felhasználónak lehetősége van előre fizetésre is. A
              Felhasználó kiválaszthatja, hogy hány hónapra szeretné előre
              kifizetni a havidíjat. Ebben az esetben a bankkártyáról történő
              levonást követően annyiszor 1 hónap használati időt ír jóvá a
              rendszer, ahány hónap kifizetésre került.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              7
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Hosszabb előfizetési idő esetén a rendszer visszaáll az eredeti
              díjlevonásra, azaz a következő időszakra akkor történik meg a
              kiterhelés, amikor a használati idő 10 napra csökken.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              8
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Ha az előfizetési idő lejárta után vagy alatt a Felhasználó
              lemondja a Szolgáltatást és a kifizetett használati idő is letelt,
              a Felhasználó Szolgáltatáshoz való hozzáférése megszűnik.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              9. Amennyiben a Felhasználó a már meglévő csomagja mellé új
              csomagra szeretne előfizetni, a rendszer arányosan csak akkora
              összeget von le a Felhasználótól az új csomag vonatkozásában,
              amennyi használati idő a már meglévő csomagban hátra van, annak
              érdekében, hogy a következő havi levonásnál minden előfizetett
              csomag esetén az összegek kiterhelése egyszerre történhessen.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              1
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              0. Az online bankkártyás fizetések a Stipe.com rendszerén
              keresztül valósulnak meg. A bankkártya-adatok a kereskedőhöz nem
              jutnak el. A szolgáltatást nyújtó Stripe Payments Europe Limited.
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_tyjcwt"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                VI. Számlázás és fizetési késedelem és pénzvisszafizetés
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. A Szolgáltató a számlát a megadott e-mail címre elektronikus
              úton küldi el.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2. Amennyiben a Felhasználó a Szolgáltató más szolgáltatásait is
              használja, úgy a felek az ottani díjakkal együtt számolnak el. A
              Felhasználó kifejezetten elfogadja, hogy ha használja a
              Szolgáltató által készített felhasználói honlap kártyás fizetési
              lehetőségét vagy egyéb szolgáltatásait, akkor a Szolgáltató az
              ottani beérkezett összegekből levonhatja a Szolgáltatás díját.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              3. Fizetési késedelem esetén Szolgáltató késedelmi kamatra
              jogosult. A késedelmi kamat mértéke a késedelemmel érintett
              naptári félév első napján érvényes jegybanki alapkamat 10
              százalékponttal növelt értéke. A felhasználói fizetési
              kötelezettség teljesítésének késedelme esetén a Szolgáltató a
              követelése behajtásával kapcsolatos költségei fedezetéül 40
              eurónak megfelelő, a Magyar Nemzeti Bank - késedelem kezdőnapján
              érvényes - hivatalos deviza-középárfolyama alapján meghatározott
              forint összegre (behajtási költségátalány) is igényt tarthat.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. Ha nem tudja használni a program hibái miatt a rendszert az
              első megrendelést követően, a szolgáltatás kiesési idejére
              pénzvisszafizetést biztosít Szolgáltató. Ezzel a Felhasználó akkor
              élhet ha bizonyítja, hogy a rendszer hibás vagy alkalmatlan a
              tevékenység gyakorlására.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              5. Szolgáltató folyamatos szolgáltatást nyújt a Felhasználók
              részére, amelyet egyszer kell megrendelni, tehát egyszer tölti ki
              Felhasználó a megrendelő lapot, ezután díjbekérőt kap a rendelt
              időszak díjáról. A kifizetett időszak lejárta előtt 10 nappal új
              díjbekérőt kap az új időszakra. Amennyiben Felhasználó csomagot,
              modult vagy fizetési ütemet szeretne váltani vagy eszközt
              vásárolni, úgy a díjbekérő levélen található csomagmódosító linken
              meg tudja tenni, így módosított díjbekérőt kap. Ha nem egyenlíti
              ki Felhasználó a díjbekérő összegét, akkor a kifizetett időszak
              utolsó napján megszűnik a szolgáltatás, Szolgáltató a fiókját
              törli a hozzáféréssel együtt és az előzményi adatok 10 nappal
              később elvesznek.
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_3dy6vkm"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                VII. A Szolgáltatás szezonális igénybevétele, szolgáltatás
                szüneteltetése, megtagadása
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              1
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató lehetőséget biztosít a Szolgáltatás
              igénybevételére, olyan Felhasználók részére is, akik szezonális
              jelleggel működnek, illetve a Szolgáltatást szezonális jelleggel
              használják.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2. A Felhasználónak lehetősége van évente egy alkalommal
              legfeljebb 6 hónapra a szolgáltatás szüneteltetését kérni. A
              szüneteltetés iránti igényt a Felhasználó a Szolgáltató felé
              kizárólag írásban e-mail üzenetben jelezheti.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              3. A szüneteltetés alatt a Felhasználó nem jogosult a Szolgáltatás
              használatára és nem köteles megfizetni a havi díjat sem. A
              szolgáltatás idő előtti esetleges újraindítását szintén email-ben
              kérheti. Az újraindítása az új időszak havi díjának befizetése
              után indul.{" "}
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. A szüneteltetés iránti kérelmet a Szolgáltató indoklás nélkül
              elutasíthatja.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Szolgáltatás megtagadása
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Amennyiben a Felhasználó ellen csőd-, felszámolási, vagy
              végelszámolási eljárás indul, a Szolgáltató jogosult újabb
              Szolgáltatás nyújtását megtagadni. A Szolgáltatás megtagadásával
              egyidejűleg a Szolgáltató jogosult azonnali hatállyal felmondani a
              Felhasználó valamennyi szerződését, azzal, hogy a teljesítés jelen
              pont szerinti megtagadása esetén a Szolgáltatót semmilyen
              kártérítési vagy szavatossági kötelezettség nem terheli.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              Szolgáltató jogosult a Szolgáltatást a szerződés megszüntetése
              nélkül
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              egyoldalúan, részlegesen vagy teljesen felfüggeszteni abban az
              esetben is, ha Felhasználó olyan magatartást tanúsít, amely a
              jelen ÁSZF különös rendelkezései szerint egyébként azonnali
              hatályú felmondásra is okot adna.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A Szolgáltatás fenti okokból történő
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              részleges vagy teljes
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              &nbsp;
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              felfüggesztése, megszüntetése vagy a szerződés Szolgáltató általi
              felmondása nem mentesíti Felhasználót a megszüntetés vagy
              felmondás időpontja előtt, illetve a felfüggesztés időpontja előtt
              és/vagy alatt már igénybe vett Szolgáltatások díjának megfizetése
              alól.
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_1t3h5sf"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                VIII. Felelősségvállalás
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. Szolgáltató felelősséget vállal a Szolgáltatás megfelelő
              működéséért és üzemeltetéséért. A szolgáltatónak fel nem róható,
              illetve más Szolgáltató közreműködésével teljesített
              üzemzavarokért, hozzáférési hibákért a Szolgáltatót nem terheli
              felelősség. A szolgáltatás eléréséhez szükséges internet
              hozzáférés és az ahhoz szükséges eszközök biztosítása Felhasználó
              feladata.{" "}
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2. Szolgáltató nem vállal felelősséget a következő esetekben:
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              a) A Szolgáltatón kívül álló technikai és hardveres, esetleg
              szoftveres hibák, problémák esetén, beleértve a szerver működése
              és nem megfelelő kapcsolódása, internetes problémák, internetes
              szakadozások, bármiféle probléma, ami visszavezethető egy vagy
              több hardver nem megfelelő működésére, különösen a Felhasználónál
              található hardverre.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              b) A Felhasználónál működő internetes hálózat és internetes
              kapcsolódás hibájából eredő problémákért. Szolgáltató nem vállal
              felelősséget a rendszer az internetszolgáltató hibájából történő
              elérhetetlenségéért, lassú működéséért.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              c) A Felhasználó által használt olyan eszközök, gépek, nyomtatók,
              perifériák, pénztárgépek stb. használatából eredő problémákért,
              melyek a Szolgáltató rendszerével nem működnek megfelelően.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              d) A Felhasználó által az étlapra feltöltött képekért.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              e) Rendszerfrissítés esetén, ha a Felhasználó által használt
              elavult eszközön nem megfelelően működik a rendszer vagy teljesen
              megszűnik a rendszer működése.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              f) Vírusos támadás, DDOS és túlterhelési támadás, Trójai vírus és
              egyéb káros szoftver esetében, továbbá ismeretlen vagy ismert
              személyek digitális támadása esetén. Szolgáltató nem vállal
              felelősséget, ha külső támadás (pl. Hekkertámadás) éri az általa
              szolgáltatott alkalmazást, és ennek következtében adatvesztés vagy
              a szolgáltatás leállása történik. Ilyen esetekben Szolgáltató a
              lehető legrövidebb időn belül kijavítja az információs rendszer
              sérülésből keletkezett károkat és helyreállítja a szolgáltatást.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              g) Olyan magatartásból eredő problémáért vagy kárért, amely a
              Szolgáltatás szakszerűtlen, jogszerűtlen, szerződésellenes
              használatából ered.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              h) Olyan károkért vagy veszteségekért, amelyek személyes/bizalmas
              információk elvesztéséből, hardver/szoftver egészének vagy egy
              részének használhatatlanságából, személyi sérülésből, illetve
              bármely kötelezettség teljesítésének elmulasztásából származó
              bármilyen jellegű károkért vagy veszteségekért.{" "}
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              i) Olyan károkért vagy veszteségekért, amelyek a Szolgáltatás
              díjának vagy bármely más egyéb díjnak, költségnek (pl. bánatdíj) a
              Felhasználó által megadott bankkártyáról történő levonásából ered.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              j) A Szolgáltató felelőssége kizárt minden olyan magatartásért
              (különös tekintettel a károkra), amely a szolgáltatás
              szakszerűtlen, jogszerűtlen ÁSZF ellenes használatából ered,
              illetve a Felhasználó a szolgáltatás igénybevételével remélt
              anyagi hasznának a vártnál csekélyebb mértékéért, az anyagi haszon
              elmaradásáért/veszteségéért.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              3. A Weboldalon bemutatott információk, leírások, szövegek
              kizárólag a tájékozódás célját szolgálják és semmilyen körülmények
              között nem minősülnek szakmai tanácsadásnak, szakmai
              tájékoztatásnak, melyre tekintettel Szolgáltató nem vállal
              felelősséget azért, ha valamely Felhasználót vagy más személyt a
              weboldalon feltüntetett tartalom értelmezéséből eredően vagy az
              információk adott esetben történő felhasználásra nem megfelelősége
              miatt kár / veszteség ér.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A Weboldalon található adatok, információk, leírások, szövegek
              semmi esetre sem helyettesítik az egyedi esetben, egyénre
              (vállalkozásra) szabott tanácsadást, konzultációt.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. A Felhasználó köteles a Szolgáltatás igénybevételéhez, illetve
              díjfizetéshez kapcsolódó adatait, azok megváltozása esetén 5
              munkanapon belül a Szolgáltató felé bejelenteni. A Szolgáltató az
              adatbejelentés elmulasztása, vagy késedelmes teljesítése esetén
              kizárja a Szolgáltató késedelmét, és az ebből eredő
              többletköltséget a Felhasználó köteles megfizetni. Szolgáltató nem
              köteles meggyőződni arról, hogy a megrendelést, illetve a
              szerződést a Felhasználó részéről az arra jogosult személy írta
              alá. Az adatbejelentés elmulasztása vagy késedelmes bejelentése
              miatti számlázási késedelem esetén nem vállalja a Szolgáltatás
              folyamatos biztosítását.{" "}
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              5. A Felhasználó köteles tartózkodni olyan eszköz használatától,
              amely a Szolgáltatás üzemeltetéséhez használt szerverek leállását
              célozza, teszi lehetővé vagy azt eredményezheti, vagy amely
              egyébként veszélyezteti a Szolgáltatás rendeltetésszerű működését.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              6
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Felhasználó felelősséget vállal azért, hogy valamely
              kiegészítő csomag keretében bérelt eszközöket a szerződés
              megszűnése esetén haladéktalanul, rendeltetésszerű használatra
              alkalmas állapotban visszaadja a Szolgáltató részére. Amennyiben a
              szerződés időtartama alatt az eszköz megsérül, megsemmisül vagy
              egyéb módon használhatatlanná válik, az ezzel kapcsolatos
              felelősség teljes egészében a Felhasználót terheli.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              7
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Amennyiben a szerződés megszűnésekor a Felhasználó az
              eszközökkel nem tud elszámolni vagy sérülten adja azokat vissza a
              Szolgáltató részére, a Szolgáltató kárának megtérítésére tarthat
              igényt, melyet a Felhasználó köteles megfizetni.
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_4d34og8"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                IX. A szerződés megszüntetése/Felmondási jog
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              1
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Felek között a jogviszony határozatlan időre jön létre.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2.
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              {" "}
                            Felhasználó a Szolgáltatást lemondhatja. A lemondást követően –
              amennyiben a kifizetett időszak is letelt - a Felhasználó
              Szolgáltatáshoz való hozzáférése megszűnik. Fő csomag lemondása
              esetén a POS rendszerhez való hozzáférés megszűnik.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A szerződést a Szolgáltató jogosult bármikor 30 napos
              határidővel, indoklás nélkül írásban felmondani.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. A szerződés azonnali hatályú felmondására a másik fél súlyos
              szerződésszegése esetén van lehetőség. Az azonnali hatályú
              felmondást meg kell indokolni. Azonnali hatályú felmondás esetén a
              Felhasználó Szolgáltatáshoz való hozzáférése azonnal megszűnik.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              5
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Súlyos szerződésszegésnek minősül többek között, de nem
              kizárólagosan,
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              a) ha a Felhasználó fizetési késedelembe esik,
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              b) ha a Szolgáltató tudomására jut, hogy a Felhasználó
              valótlan/jogsértő adatokat adott meg a szerződéskötés során,
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              c) ha a Felhasználó Szolgáltatóval szembeni üzleti kommunikációja
              vagy magatartása súlyosan sértő, kifogásolható,
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              d) ha a Felhasználó ellen csőd-, felszámolási, vagy végelszámolási
              eljárás indul.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              6
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Szolgáltató jogosult a Szolgáltatást egyoldalúan felfüggeszteni
              és a hozzáférést megtagadni abban az esetben, ha a Felhasználó
              olyan magatartást tanúsít, amely azonnali hatályú felmondásra is
              okot adna.
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_2s8eyo1"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                X. Titoktartás
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              1
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató kötelezettséget vállal arra, hogy védi és őrzi,
              üzleti titokként bizalmasan kezeli a Szolgáltatás teljesítése
              során tudomására jutott adatokat, információkat, dokumentumokat és
              minden erőfeszítést megtesz annak érdekében, hogy azok megfelelő
              védelmét biztosítsák. Szolgáltató részére átadott valamennyi adat,
              információ üzleti titoknak minősül, mint ilyen bizalmasan
              kezelendő és harmadik személyek tudomására hozni tilos a
              Felhasználó írásbeli engedélye nélkül.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                        <a id="_17dp8vu"/>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2. A Szolgáltató és Felhasználó a bizalmas adatokat és
              információkat csak a Szolgáltatás teljesítése céljából
              használhatják fel, a tudomásukra jutott adatokat, információkat
              pedig csak a másik fél előzetes írásbeli hozzájárulásával
              hozhatják nyilvánosságra kivéve, ha a közlésre jogszabály kötelez.
              Jelen rendelkezés nem érinti a Szolgáltató azon jogát, hogy
              statisztikai adatokat (pl. forgalom, átlagos kiszállítási idő,
              népszerű ételek stb.) hozzon nyilvánosságra a Felhasználó
              beazonosíthatósága nélkül.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Felhasználót teljes körű felelősség terheli jelszavának
              titokban tartásával kapcsolatosan.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. A Szolgáltató a Felhasználó által rendelkezésére bocsátott
              személyes adatokat bizalmasan kezeli, kizárólag a Szolgáltatás
              teljesítéséhez használja fel.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              5
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Nem tekinthető a titoktartási kötelezettség megszegésének, ha
              bármelyik fél az erre illetékes hatóság, bíróság felhívására a
              hatósággal, bírósággal közli az üzleti titoknak, illetve
              bizalmasnak minősített információkat, adatokat.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              6. Felhasználó tudomásul veszi, hogy a jelen ÁSZF elfogadásával
              hozzájárul ahhoz, hogy Szolgáltató a Felhasználó esetleges
              fizetési késedelme vagy nem szerződésszerű teljesítése esetén a
              követelés behajtását harmadik félre bízza és neki a Felhasználó
              adatait átadja. Felhasználó kijelenti, hogy ezen eljárást nem
              tekinti az üzleti titok megsértésének, illetve a személyes
              adatokkal történő visszaélésnek.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              7. A Szolgáltató a Felhasználó által önkéntes adatszolgáltatás
              keretében rendelkezésére bocsátott személyes adatokat bizalmasan
              kezeli, kizárólag az egyes Felhasználók azonosításához, illetve a
              szolgáltatás teljesítéséhez, a sikeres teljesítéshez szükséges
              mértékben és az elektronikus kereskedelmi szolgáltatások, valamint
              az információs társadalommal összefüggő szolgáltatások egyes
              kérdéseiről szóló 2001. évi CVIII. törvény 13/A. §-ában
              meghatározottak szerint használja fel. A Felhasználó személyes
              adatainak jelen szerződésben meghatározott felhasználásához jelen
              Szerződés megkötésével egyben hozzájárulását adja.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              8. A Szolgáltató köteles a Felhasználó által a Szolgáltató
              rendszerbe rögzített adatokat a Felhasználó kérésére
              haladéktalanul törölni. Amennyiben a Felhasználó a törlést nem
              kéri, a szerződés megszűnését követően a Szolgáltató a Felhasználó
              által a Szolgáltató rendszerbe rögzített adatokat 30 napig
              tárolja.
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_3rdcrjn"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XI. Szavatosság/Jótállás/Hibás teljesítés
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              Felhasználó jelen szerződésben: Vállalkozásnak minősülő
              Felhasználó. Definíciója: olyan személy, aki a szakmája önálló
              foglalkozása vagy üzleti tevékenysége körében jár el.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              Hibás teljesítés: A kötelezett hibásan teljesít, ha a szolgáltatás
              a teljesítés időpontjában nem felel meg a szerződésben vagy
              jogszabályban megállapított minőségi követelményeknek. Nem
              teljesít hibásan a kötelezett, ha a jogosult a hibát a
              szerződéskötés időpontjában ismerte, vagy a hibát a szerződéskötés
              időpontjában ismernie kellett.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. A Felhasználó a Szolgáltató hibás teljesítése esetén a
              Szolgáltatóval szemben termék és vagy kellékszavatossági igényt
              érvényesíthet a Polgári Törvénykönyv szabályai szerint.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2. A Felhasználó kérhet kijavítást vagy kicserélést a
              Szolgáltatónál vásárolt Hardware eszközökre, kivéve, ha az ezek
              közül a Felhasználó által választott igény teljesítése lehetetlen
              vagy a Szolgáltató számára más igénye teljesítéséhez képest
              aránytalan többletköltséggel járna. Ha a Felhasználó a kijavítást
              vagy a kicserélést nem kérte, illetve nem kérhette, úgy
              igényelheti az ellenszolgáltatás arányos leszállítását, végső
              esetben - a szerződéstől is elállhat. Jelentéktelen hiba miatt
              elállásnak nincs helye.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              3. A Felhasználó választott kellékszavatossági jogáról egy másikra
              is áttérhet, az áttérés költségét azonban ő viseli, kivéve, ha az
              indokolt volt, vagy arra a vállalkozás adott okot.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              4. A Felhasználó köteles a hibát annak felfedezése után
              haladéktalanul közölni a Szolgáltatóval. A Felhasználó köteles
              bizonyítani, hogy a hiba már a teljesítés időpontjában is megvolt.
              A Felhasználó a szerződés teljesítésétől számított egy éves
              elévülési határidőn túl kellékszavatossági jogait már nem
              érvényesítheti.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              5. A 45/2014. (II.26.) Korm. rendelet szerinti 14 napos elállási
              jog csak a fogyasztókat illeti meg. Jelen szerződés vonatkozásában
              a Felhasználó az ÁSZF elfogadásával nyilatkozik, hogy nem minősül
              fogyasztónak, így az elállási jog őt nem illeti meg.
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_26in1rg"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XII. Szerzői jogok
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. A Weboldalon található valamennyi tartalom (a Weboldal grafikai
              megjelenése is) és a Szolgáltatótól weboldalról vásárolt
              valamennyi tartalom (beleértve Happy Gastro vendéglátós
              software/program rendszert) annak megjelenési formájától
              függetlenül szerzői jogi védelem alatt áll a szerzői jogról szóló
              1999. évi LXXVI. törvény alapján.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A szerzői jogi védelem alapján a szerzőnek kizárólagos joga van a
              mű egészének vagy valamely azonosítható részének anyagi formában
              és nem anyagi formában történő bármilyen felhasználására és minden
              egyes felhasználás engedélyezésére. Kifejezett írásbeli
              hozzájárulásával engedélyezi Szolgáltató.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A{" "}
            </span>
                        <a href="http://www.happygastro.hu" style={{textDecoration: "none"}}>
                            <u>
                <span
                    style={{
                        fontFamily: "Avenir",
                        color: "#212529",
                        backgroundColor: "#f7f8fc",
                    }}
                >
                  www.happygastro.hu
                </span>
                            </u>
                        </a>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              {" "}
                            weboldalról és annak adatbázisából bármilyen anyagot átvenni
              írásos hozzájárulás esetén is csak az adott weboldalra való
              hivatkozással lehet. A szolgáltató fenntartja minden jogát
              szolgáltatásának valamennyi elemére, a domain-neveire, az azokkal
              képzett másodlagos domain nevekre valamint az internetes
              reklámfelületeire.
            </span>
                    </p>
                    <p className="normal0">
                        Tilos a{" "}
                        <a href="http://www.happygastro.hu" style={{textDecoration: "none"}}>
                            <u>
                                <span style={{color: "#000000"}}>www.happygastro.hu</span>
                            </u>
                        </a>{" "} weboldal és az onnan a Szolgáltató által ellenszolgáltatás ellenében felhasználásra
                        engedélyezett Happy Gastro vendéglátós software bármely részéről másolatot készíteni, ill. annak
                        részeit adaptálni vagy visszafejteni, és tilos a felhasználói azonosítók és jelszavak
                        tisztességtelen módon történő létesítése, ill. bármely olyan alkalmazás használata amellyel
                        a{" "}
                        <a href="http://www.happygastro.hu" style={{textDecoration: "none"}}>
                            <u>
                                <span style={{color: "#000000"}}>www.happygastro.hu</span>
                            </u>
                        </a>{" "} weboldalon vagy azok bármely része módosítható vagy indexelhető.
                    </p>

                    <p className="normal0">
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              .{" "}
            </span> Kifejezetten tilos a Weboldalon található tartalmakról és a Szolgáltató által ellenszolgáltatás
                        ellenében felhasználásra engedélyezett Happy Gastro vendéglátós software bármely részéről
                        másolatot készíteni, azokat bármely módon átvenni, bármilyen módon sokszorosítani, nyilvánosan
                        bemutatni vagy közvetíteni, más helyen részben vagy egészben közzétenni, más módon való
                        felhasználása, elektronikus tárolása, feldolgozása és értékesítése. Más személyek nem
                        használhatják a Weboldalra feltöltött szövegeket, a Happy Gastro software program részeit a
                        saját termékükhöz, szolgáltatásukhoz. A Felhasználó a Weboldalon található tartalmakat és onnan
                        vásárolt vendéglátós software részeit vagy egészét – a térítés ellenében hozzáférhető tartalmat
                        is ide értve – nem értékesítheti tovább, saját termékeihez, szolgáltatásához nem használhatja,
                        azok alapján nem hozhat létre saját terméket vagy szolgáltatást.
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              3. Amennyiben a Szolgáltató felhasználói felületet biztosít, mely
              felületre a Felhasználó/Megrendelő felhasználónév és jelszó
              használatával beléphet, úgy annak a felületnek a hozzáférését
              Felhasználó/Megrendelő nem oszthatja meg másokkal, amennyiben
              mégis megosztja és ezzel ő vagy a harmadik személy a
              Szolgáltatónak kárt okoz, úgy Felhasználó / Megrendelő köteles a
              Szolgáltatónak az ezzel okozott kárt megtéríteni.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              4
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Felhasználó tudomásul veszi, hogy a felhasználási engedély
              nélküli felhasználás esetén Szolgáltatót kötbér illeti meg. A
              kötbér összege képenként bruttó 60.000.-Ft, illetve szavanként
              bruttó 20.000.-. A szerzői jogok megsértéséért, az oldal
              másolásáért a Felhasználó 2.000.000,-Ft+ÁFA, azaz kétmillió forint
              + ÁFA kötbért köteles fizetni a Szolgáltatónak oldalanként,
              amelyről Szolgáltató kötbér számlát állít ki a jogsértő részére.
              Felhasználó tudomásul veszi, hogy ezen kötbérkikötés nem túlzó, és
              ennek tudatában használja az oldalt. A szerzői jogi jogsértés
              esetén Szolgáltató közjegyzői ténytanúsítást alkalmaz, melynek
              összegét szintén a jogsértő felhasználóra hárítja.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A Szolgáltató, illetve a szerző a jogsértő személlyel szemben a
              kötbért meghaladó kárát is jogosult követelni, kárigényét vele
              szemben érvényesíteni.
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_lnxbz9"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XIII. Jogi nyilatkozat
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. A Szolgáltató kizár minden felelősséget a Weboldal és a
              Szolgáltatás használói által tanúsított magatartásért. Amennyiben
              jogsértés merül fel a Szolgáltató együttműködik az eljáró
              hatóságokkal a felderítés végett.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Szolgáltató nem vállal felelősséget a szerver esetleges
              meghibásodásából, a Weboldal tartalmának harmadik fél általi
              megváltoztatásából származó károkért.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Tilos a Weboldalon megjelenő tartalmak, így különösen a
              blogbejegyzések vagy azok egy részének másolása vagy más módon
              való felhasználása, feldolgozása és értékesítése a Szolgáltató
              írásos hozzájárulása nélkül.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              4
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Felhasználó a Szolgáltatás megrendelése során köteles a saját,
              valós adatait megadni. Szolgáltató kizárja felelősségét,
              amennyiben Felhasználó más nevében, más személy adataival veszi
              igénybe szolgáltatásait. A Szolgáltatót a Felhasználó által
              tévesen és/vagy pontatlanul megadott adatokra visszavezethető
              késedelemért, illetve egyéb problémáért, hibáért semminemű
              felelősség nem terheli.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              5
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Amennyiben a Szolgáltató minden gondossága ellenére hibás ár
              kerül a Weboldal felületére, különös tekintettel a nyilvánvalóan
              téves, például a Szolgáltatás közismert, általánosan elfogadott
              vagy becsült árától jelentősen eltérő, esetleg rendszerhiba miatt
              megjelenő 0,-Ft-os vagy 1,-Ft-os árra, akkor a Szolgáltató nem
              köteles a Szolgáltatást hibás áron értékesíteni, hanem
              felajánlhatja a helyes áron történő szolgáltatásnyújtást, amelynek
              ismeretében a Felhasználó elállhat megrendelési szándékától.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              6
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Szolgáltató a szerzői jog jogosultja a honlapon keresztül
              elérhető, Szolgáltatások nyújtása során megjelenített valamennyi
              tartalomnak: bármely szerzői műnek, illetve más szellemi
              alkotásnak (ideértve valamennyi elkészíttetett grafikát, képet,
              videót, fotót és a honlap felületének elrendezését, ötleteket,
              megvalósításokat).
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              7. Szolgáltató fenntart magának minden jogot a Weboldal, annak
              bármely részlete és az azon megjelenő tartalmak tekintetében.
              Tilos a Weboldalon megjelenő tartalmak vagy azok bármely
              részletének letöltése, elektronikus tárolása, feldolgozása és
              értékesítése a Szolgáltató írásos hozzájárulása nélkül.
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_35nkun2"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XIV. Vis Maior
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              Vis maiornak minősül minden olyan rendkívüli, a szerződés
              létrejötte után bekövetkező, annak teljesítését lehetetlenné tevő
              esemény, amelyet szerződő felek nem láthattak előre, és nem
              háríthattak el, és amely nem vezethető vissza egyik fél felróható
              magatartására sem. Ilyen események lehetnek különösen:
              szükségállapot, sztrájk, háború, forradalom, terrorista
              cselekmények, természeti katasztrófa, tűzvész, árvíz, járvány,
              karantén korlátozások, szállítási embargó stb. Vis maior esetén
              mindkét fél mentesül a szerződés teljesítésének kötelezettsége
              alól.
            </span>
                    </p>


                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_1ksv4uv"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XV. Adatvédelem
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              A weboldal adatkezelési tájékoztatója elérhető a következő
              oldalon: www.happygastro.hu/adatvédelem
            </span>
                    </p>


                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_1ksv4uv"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XVI. ELÁLLÁS JOGA
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
                <ul>
                    <li>1. Az Európai Parlament és a Tanács 2011/83/EU számú irányelvének, továbbá a fogyasztó és a vállalkozás közötti szerződések részletes szabályairól szóló 45/2014. (II.26.) Korm. rendelet szabályozása értelmében a fogyasztó a megrendelt termék kézhez vételétől számított 14 napon belül indokolás nélkül elállhat a szerződéstől, visszaküldheti a megrendelt terméket.</li>

<li>2. Az elállási jog nem illeti meg a Felhasználót, ha az vállalkozás, azaz a jogi személy, továbbá az olyan személyt, aki a szakmája, önálló foglalkozása vagy üzleti tevékenysége körében jár el!</li>
<li>3. Az elállási jog gyakorlására nyitva álló idő attól a naptól számított 14 nap elteltével jár le, amelyen Felhasználó, vagy az általa megjelölt vagy üzletkörében képviselőként eljáró, a fuvarozótól eltérő harmadik személy a terméket átveszi.</li>
                </ul>
            </span>
                    </p>


                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_44sinio"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XVII. Panaszügyintézés és jogérvényesítés{" "}
              </span>
                        </strong>
                    </p>
                    <p className="normal0">
                        <span style={{fontFamily: "Avenir"}}>1. </span>
                        <span style={{fontFamily: '"Helvetica Neue"', fontSize: "11pt"}}>
              Panaszügyintézés és jogérvényesítés{" "}
            </span>
                    </p>
                    <p className="normal0">
                <span style={{fontFamily: '"Helvetica Neue"', fontSize: "11pt"}}>
              1. A Felhasználó kifogásait a Szolgáltató felé e-mail üzenetben
              terjesztheti elő az{" "}
            </span>
                        <a href="mailto:info@Happygastro.hu" style={{textDecoration: "none"}}>
                            <u>
                <span
                    style={{
                        fontFamily: '"Helvetica Neue"',
                        fontSize: "11pt",
                        color: "#000000",
                    }}
                >
                  info@Happygastro.hu
                </span>
                            </u>
                        </a>
                        <span style={{fontFamily: '"Helvetica Neue"', fontSize: "11pt"}}>
              {" "}
                            e-mail címen. A Szolgáltató a felhasználói kifogásokra legkésőbb
              30 napon belül válaszol. Szolgáltató a szóbeli panaszt azonnal
              megvizsgálja és szükség esetén orvosolja. Ha a Felhasználó a
              panasz kezelésével nem ért egyet vagy a panasz azonnali
              kivizsgálása nem lehetséges a Szolgáltató a panaszról és az azzal
              kapcsolatos álláspontjáról haladéktalanul jegyzőkönyvet vesz fel s
              annak egy másolati példányát átadja a Felhasználónak.{" "}
            </span>
                    </p>
                    <p className="normal0" style={{fontSize: "11pt"}}>
                <span style={{fontFamily: '"Helvetica Neue"'}}>
              2. Amennyiben a Szolgáltató és a Felhasználó között esetlegesen
              fennálló jogvita nem rendeződik, a Felhasználó bírósághoz
              fordulhat. A Felek a közöttük létrejött szerződéses jogviszonyból
              fakadó bármely jogvita esetére alávetik magukat
            </span>
                        <strong>
                            <span style={{fontFamily: '"Helvetica Neue"'}}> </span>
                        </strong>
                        <span style={{fontFamily: '"Helvetica Neue"'}}>
              – hatáskörtől függően – Pesti Központi Kerületi Bíróság vagy a
              Fővárosi Törvényszék illetékességének.
            </span>
                    </p>
                    <p className="normal0" style={{pageBreakAfter: "avoid", fontSize: "14pt"}}>
                        <a id="_2jxsxqh"/>
                        <strong>
              <span
                  style={{
                      fontFamily: '"Helvetica Neue"',
                      backgroundColor: "#ffffff",
                  }}
              >
                XVIII. Egyéb rendelkezések
              </span>
                        </strong>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              1. A Szolgáltató és a Felhasználó közötti jogviszonyra a magyar
              jog az irányadó.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              2
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Jelen ÁSZF elektronikus formában és kizárólag magyar nyelven
              kerül megkötésre. Az így létrejött szerződés nem minősül írásba
              foglalt szerződésnek és nem kerül iktatásra.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              3
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Szolgáltató kötelezettsége teljesítéséhez közreműködőt jogosult
              igénybe venni, melynek esetleges jogellenes magatartásáért teljes
              felelősséggel tartozik, úgy mintha a jogellenes magatartást saját
              maga követte volna el.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              4
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . Jelen ÁSZF-et a Szolgáltató indokolás nélkül bármikor
              módosíthatja, erről a Felhasználót a Weboldalon keresztül
              értesíti. Amennyiben a Felhasználó az ÁSZF módosítást nem fogadja
              el, úgy a szerződése azonnali hatállyal megszüntetésre kerül és a
              továbbiakban nem jogosult a Szolgáltatás használatára.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              5
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A jelen ÁSZF-ben nem szabályozott kérdésekben a Polgári
              Törvénykönyv és az egyéb irányadó jogszabályok rendelkezéseit kell
              alkalmazni.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#ffffff",}}>
              6
            </span>
                        <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              . A Weboldal használatával, továbbá megrendelés leadásával és a
              Szolgáltatás használatával a Felhasználó elfogadja az ÁSZF
              tartalmát.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              7.Ha a jelen Szabályzat bármely része érvénytelenné, jogtalanná
              vagy érvényesíthetetlenné válik, az a fennmaradó részek
              érvényességét, jogszerűségét és érvényesíthetőségét nem érinti.
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              8.Amennyiben Szolgáltató a Szabályzat alapján megillető jogát nem
              gyakorolja, a joggyakorlás elmulasztása nem tekinthető az adott
              jogról való lemondásnak. Bármilyen jogról történő lemondás csak az
              erre vonatkozó kifejezett írásbeli nyilatkozat esetén érvényes. Az
              hogy a Szolgáltató egy alkalommal nem ragaszkodik szigorúan a
              Szabályzat valamely lényegi feltételéhez, vagy kikötéséhez nem
              jelenti azt, hogy lemond arról, hogy a későbbiekben ragaszkodjon
              az adott feltétel vagy kikötés szigorú betartásához.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              9. Szolgáltató és Felhasználó vitás ügyeiket békés úton próbálják
              rendezni.
            </span>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              &nbsp;
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              10. Felek rögzítik, hogy a Szolgáltató weboldala/mobil
              applikációja Magyarországon működik, karbantartását is itt végzik.
              Mivel az oldal más országokból is meglátogatható, ezért a
              felhasználók kifejezetten tudomásul veszik, hogy a felhasználó és
              a Szolgáltató viszonylatában az irányadó jog a magyar jog.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              11. A jelen Szabályzatban nem szabályozott kérdésekre, valamint
              jelen Szabályzat értelmezésére a magyar jog az irányadó, különös
              tekintettel a Polgári Törvénykönyvről szóló 2013. évi V. törvény
              („Ptk.”) és az elektronikus kereskedelmi szolgáltatások, az
              információs társadalommal összefüggő szolgáltatások egyes
              kérdéseiről szóló 2001. évi CVIII. (Elker. tv.) törvény. A
              vonatkozó jogszabályok kötelező rendelkezései a felekre külön
              kikötés nélkül is irányadók.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "16pt", textAlign: "justify"}}>
                <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              12. A jelen Szabályzat{" "}
            </span>
                        <strong>
              <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
                2023. július 1
              </span>
                        </strong>
                        <span style={{fontFamily: "Avenir", color: "#1c252c"}}>
              . napjától hatályos és visszavonásig hatályban marad. A
              Szolgáltató jogosult egyoldalúan módosítani a Szabályzatot (a
              módosításra okot adó körülmények: jogszabály-változás, üzleti
              érdek, céggel kapcsolatos változások), mely módosítás esetén a
              Felhasználónak joga van a szerződéstől elállni, vagy azt
              felmondani.
            </span>
                    </p>
                    <p className="normal0" style={{marginBottom: "15pt"}}>
                <span style={{fontFamily: "Avenir", color: "#212529", backgroundColor: "#f7f8fc",}}>
              2024.04.15
            </span>
                    </p>
                    <div style={{clear: "both"}}>
                        <p className="normal0">&nbsp;</p>
                        <p className="normal0">&nbsp;</p>
                        <p className="normal0">&nbsp;</p>
                    </div>
                </div>
            </div>
            <FooterOne/>
        </Layout>
    );
};
export default AszfIndex;
